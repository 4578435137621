import { ComboBox, DefaultButton, Dialog, DialogFooter, DialogType, IComboBoxOption, IDialogContentProps, PrimaryButton } from "@fluentui/react";
import * as React from "react";
import { getI18n, useTranslation } from "react-i18next";

interface Language {
    isoCode: string,
    name: string,
}
export interface INewEntityNameDialogProps {
    excludedIsoCodes?: string[],
    onClose: (confirmed: boolean, isoCode?: string) => void;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function NewEntityNameDialog(props: INewEntityNameDialogProps) {
    const { t } = useTranslation("translation", { keyPrefix: "newLanguageNameDialog" });
    const { t: tGeneral } = useTranslation("translation", { keyPrefix: "general" });
    const [language, setLanguage] = React.useState<Language|undefined>(undefined);
    const saveDisabled = React.useMemo(() => !language , [language]);

    const modalProps = React.useMemo(() => ({
        isBlocking: false,
    }), []);

    const dialogContentProps = React.useMemo(() => ({
        type: DialogType.normal,
        title: t("title"),
        closeButtonAriaLabel: 'Close',
        subText: t("message"),
    }) as IDialogContentProps, [t]);

    const languages = React.useMemo(() => {
        const currentLang = getI18n().language;
        // eg use "de" as fallback when currentLang is "de-DE"
        const languageSubCode = currentLang.match(/^([a-z]{2,3})-/i)?.[1]
        
        let resourceBundle = getI18n().getResourceBundle(currentLang, "translation");
        if (!resourceBundle && languageSubCode) resourceBundle = getI18n().getResourceBundle(languageSubCode, "translation");
        if (!resourceBundle) return [];
        const values =  Object.entries(resourceBundle.languages ?? {}).map(l => ({isoCode: l[0], name: l[1]} as Language));
        return values.filter(v => !props.excludedIsoCodes?.find(ex => ex === v.isoCode));
    }, [props.excludedIsoCodes]);

    const options = React.useMemo(() => {
        return languages.map((l, i) => ({
            key: l.isoCode,
            text: l.name,
            data: l,
        })) as IComboBoxOption[]
    }, [languages]);

    return (
        <Dialog
            hidden={false}
            onDismiss={() => props.onClose(false)}
            dialogContentProps={dialogContentProps}
            modalProps={modalProps}
        >
            <ComboBox  
                label={t("language")}
                options={options}
                selectedKey={language?.isoCode}
                onChange={(_ev, item) => setLanguage(item?.data)}
                />
            <DialogFooter>
                <PrimaryButton disabled={saveDisabled} onClick={() => props.onClose(true, language?.isoCode)} text={tGeneral("confirm")} />
                <DefaultButton onClick={() => props.onClose(false)} text={tGeneral("cancel")} />
            </DialogFooter>
        </Dialog>
    );
}