import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { PartialTheme, ThemeProvider } from "@fluentui/react";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { AppContainer } from "react-hot-loader";

import { BrowserRouter } from 'react-router-dom';
import { initI18n } from "../i18n/i18n";
import { msalConfig } from "./authConfig";
import AdminPanel from "./components/AdminPanel";

const msalInstance = new PublicClientApplication(msalConfig);
/* global document, module, require */

initializeIcons();

initI18n();

const appTheme: PartialTheme = {
  palette: {
    themePrimary: '#b3c618',
    themeLighterAlt: '#fcfdf4',
    themeLighter: '#f2f6d3',
    themeLight: '#e7eeaf',
    themeTertiary: '#d0dd68',
    themeSecondary: '#bbce2e',
    themeDarkAlt: '#a1b315',
    themeDark: '#889712',
    themeDarker: '#646f0d',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralSecondaryAlt: '#8a8886',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff',
  }
};

const render = (Component: any) => {
  ReactDOM.render(
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <ThemeProvider theme={appTheme}>
          <AppContainer>
            <Component />
          </AppContainer>
        </ThemeProvider>
      </BrowserRouter>
    </MsalProvider>,
    document.getElementById("container")
  );
};

if ((module as any).hot) {
  (module as any).hot.accept("./components/AdminPanel", () => {
    const NextApp = require("./components/AdminPanel").default;
    render(NextApp);
  });
}

(() => {
  render(AdminPanel);
})();