import { CommandBar, ICommandBarItemProps } from "@fluentui/react/lib/CommandBar";
import { DetailsList, IColumn } from "@fluentui/react/lib/DetailsList";
import { SearchBox } from "@fluentui/react/lib/SearchBox";
import { StylesClassMapping } from "@fluentui/react/lib/Theme";
import { IObjectWithKey, Selection, SelectionMode } from "@fluentui/utilities";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export interface IFlexibleColumn extends Omit<IColumn, "fieldName"> {
    fieldName: string;
}

export interface ButtonConfiguration {
    /**
     * Determines if the button is visible. Default value is false.
     */
    visible?: boolean;
    /** 
     * Text of the button. If no value is given, the default text will be used.
     */
    text?: string;
    /** 
     * Aria label of the button. If no value is given, the default text will be used.
     */
    ariaLabel?: string;
    /**
     * Event happening on click.
     */
    onClick?: () => void;
}

export interface DeleteButtonConfiguration<TItem> extends ButtonConfiguration {
    onDelete: (items: TItem[]) => Promise<void>;
}

export interface GenericListProps<TItem> {
    styles: StylesClassMapping<{
        userList: {
            display: string;
            flexDirection: string;
        };
        list: {};
        searchBox: {};
    }>;
    onChangeSearch: (value: string | undefined) => void;
    displayedItems: TItem[];
    columns: IFlexibleColumn[];
    selectionMode: SelectionMode.multiple | SelectionMode.none | SelectionMode.single;
    /**
     * Triggered when the selection changes
     */
    onSelectionChanged?: (items: TItem[]) => void;
    onItemInvoked?: (item: TItem) => void;

    newButton?: ButtonConfiguration;
    deleteButton?: DeleteButtonConfiguration<TItem>;
    editButton?: ButtonConfiguration;

    ariaLabelForSelectionColumn?: string;
    ariaLabelForSelectAllCheckbox?: string;
    checkButtonAriaLabel?: string;
}


export const GenericList = <TItem extends { id: number }>(props: GenericListProps<TItem>) => {
    const { t } = useTranslation("translation", { keyPrefix: "genericLists" });
    const [selectedItems, setSelectedItems] = useState<TItem[]>([]);
    const [disabledDelete, setDisabledDelete] = useState<boolean>(true);
    const [disabledEdit, setDisabledEdit] = useState<boolean>(true);

    const selection = useMemo(
        () =>
            new Selection({
                onSelectionChanged: () => {
                    setSelectedItems((selection.getSelection() ?? []) as TItem[]);
                },
                getKey: (item: IObjectWithKey) => (item as TItem).id,
                // selectionMode: SelectionMode.multiple,
            }),
        []);

    React.useEffect(() => {
        if (!props.onSelectionChanged) return;
        props.onSelectionChanged(selectedItems as TItem[]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItems]);

    useEffect(() => {
        if (selectedItems == null) {
            setDisabledDelete(true);
            setDisabledEdit(true);
            return;
        }
        if (selectedItems.length > 0) {
            setDisabledDelete(false);
            setDisabledEdit(false);
            if (selectedItems.length > 1) {
                setDisabledEdit(true);
            }
        }
        else {
            setDisabledDelete(true);
            setDisabledEdit(true);
        }
    }, [selectedItems])

    const listButtons = React.useMemo(() => {
        const buttons: ICommandBarItemProps[] = [];

        if (props.newButton?.visible ?? true) {
            buttons.push({
                key: 'newItem',
                text: props.newButton?.text ?? t("newButtonText"),
                iconProps: { iconName: 'Add' },
                ariaLabel: props.newButton?.ariaLabel ?? t("newButtonAriaLabel"),
                onClick: props.newButton?.onClick,
            });
        }
        if (props.editButton?.visible ?? true) {
            buttons.push({
                key: 'editItem',
                text: props.editButton?.text ?? t("editButtonText"),
                iconProps: { iconName: 'Edit' },
                ariaLabel: props.editButton?.ariaLabel ?? t("editButtonAriaLabel"),
                disabled: disabledEdit,
                onClick: props.editButton?.onClick
            });
        }
        if (props.deleteButton?.visible ?? false) {
            buttons.push({
                key: 'deleteItem',
                text: props.deleteButton?.text ?? t("deleteButtonText"),
                iconProps: { iconName: 'Delete' },
                ariaLabel: props.deleteButton?.ariaLabel ?? t("deleteButtonAriaLabel"),
                disabled: disabledDelete,
                onClick: () => { props.deleteButton?.onDelete(selectedItems); },
            });
        }
        return buttons;
    }, [disabledDelete, disabledEdit, props, selectedItems, t]);

    return (
        <div>
            <div className="CommandBar">
                <CommandBar items={listButtons}></CommandBar>
            </div>
            <div className={props.styles.userList}>
                <div>
                    <SearchBox onChange={(event, newValue) => props.onChangeSearch(newValue)} />
                </div>
                <div>
                    <DetailsList
                        items={props.displayedItems}
                        columns={props.columns}
                        selection={selection}
                        selectionMode={props.selectionMode}
                        onItemInvoked={props.onItemInvoked}
                        selectionPreservedOnEmptyClick={true}
                        ariaLabelForSelectionColumn={props.ariaLabelForSelectionColumn ?? t("ariaLabelForSelectionColumn")}
                        ariaLabelForSelectAllCheckbox={props.ariaLabelForSelectAllCheckbox ?? t("ariaLabelForSelectAllCheckbox")}
                        checkButtonAriaLabel={props.checkButtonAriaLabel ?? t("checkButtonAriaLabel")}
                    />
                </div>
            </div>
        </div>
    );
}