import React from "react";
import { useCreateCompany, useDeleteCompany, useGetCompanies, useGetCompanyDependentProfileCount, useUpdateCompany } from "./companiesApi";
import { useCreateLanguage, useDeleteLanguage, useGetLanguageDependentProfileCount, useUpdateLanguage } from "./languagesApi";
import { useCreateLocation, useDeleteLocation, useGetLocationDependentProfileCount, useUpdateLocation } from "./locationsApi";
import { useCreateTemplate, useDeleteTemplate, useDownloadTemplate, useGetTemplate, useGetTemplates, useUpdateTemplate } from "./templatesApi";
import { useCreateUser, useDeleteUser, useGetAdUsers, useGetUsers, useSetUser, useUpdateUser } from "./usersApi";
import { useWhoAmI } from "./whoamiApi";

export const useAdminApi = () => {
    const getWhoAmI = useWhoAmI();
    const getUsers = useGetUsers();
    const getAdUsers = useGetAdUsers();
    const getCompanies = useGetCompanies();
    const updateCompany = useUpdateCompany();
    const createCompany = useCreateCompany();
    const deleteCompany = useDeleteCompany();
    const getCompanyDependentProfileCount = useGetCompanyDependentProfileCount();
    const updateLocation = useUpdateLocation();
    const createLocation = useCreateLocation();
    const deleteLocation = useDeleteLocation();
    const getLocationDependentProfileCount = useGetLocationDependentProfileCount();
    const updateLanguage = useUpdateLanguage();
    const createLanguage = useCreateLanguage();
    const deleteLanguage = useDeleteLanguage();
    const getLanguageDependentProfileCount = useGetLanguageDependentProfileCount();
    const updateUser = useUpdateUser();
    const setUser = useSetUser();
    const deleteUser = useDeleteUser();
    const createUser = useCreateUser();
    const getTemplates = useGetTemplates();
    const getTemplate = useGetTemplate();
    const updateTemplate = useUpdateTemplate();
    const deleteTemplate = useDeleteTemplate();
    const createTemplate = useCreateTemplate();
    const downloadTemplate = useDownloadTemplate();

    const api = React.useMemo(() => {
        return {
            getWhoAmI,
            getUsers,
            getAdUsers,
            getCompanies,
            updateCompany,
            createCompany,
            deleteCompany,
            getCompanyDependentProfileCount,
            updateLocation,
            createLocation,
            deleteLocation,
            getLocationDependentProfileCount,
            updateLanguage,
            createLanguage,
            deleteLanguage,
            getLanguageDependentProfileCount,
            updateUser,
            setUser,
            deleteUser,
            createUser,
            getTemplates,
            getTemplate,
            updateTemplate,
            deleteTemplate,
            createTemplate,
            downloadTemplate
        }
    }, [getWhoAmI, getUsers, getAdUsers, getCompanies, updateCompany, createCompany, deleteCompany, getCompanyDependentProfileCount, updateLocation, createLocation, deleteLocation, getLocationDependentProfileCount, updateLanguage, createLanguage, deleteLanguage, getLanguageDependentProfileCount, updateUser, setUser, deleteUser, createUser, getTemplates, getTemplate, updateTemplate, deleteTemplate, createTemplate, downloadTemplate])
    return api;
}
