import { makeStyles } from "@fluentui/react";
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let style = makeStyles((theme) => ({
        layout: {
            height: "100%",
            display: "flex",
            flexDirection: "column",
            backgroundColor: theme.palette.white,
        },
        titlebar: {
            padding: "-100px"
        },
        navAndBodyWrapper: {
            display: "flex",
            flexBasis: "100%",
            overflow: "hidden",
        },
        navMenuWrapper: {
            flexShrink: "0",
            backgroundColor: theme.palette.white,
            overflowY: "auto",
            minWidth: "15%",
            padding: "14px 14px 0px 0px",
        },
        content: {
            flexBasis: "100%",
            overflowY: "auto",
            padding: "14px 14px 0px 0px",
            transition: "padding 0.5s",

        },
        contentTile: {
            height: "87vh"
        }
    }));
    return style();
};