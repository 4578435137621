import React from "react";
import { Profile } from "../model/Profile";
import { NullableOptional } from "../model/nullable";
import { generatePatchDoc } from "../utils/buildPatchDoc";
import { useFetch } from "./fetch";
import useGenericDeleteApiCall from "./useGenericDeleteApiCall";

export const useGetProfiles = () => {
    const { fetchJson } = useFetch();

    return React.useCallback(async (): Promise<Profile[] | undefined> => {
        const token = await OfficeRuntime.auth.getAccessToken();
        return await fetchJson("profiles", token) as Profile[];
    }, [fetchJson]);
}

export const useGetProfile = () => {
    const { fetchJson } = useFetch();

    return React.useCallback(async (id: number): Promise<Profile | undefined> => {
        const token = await OfficeRuntime.auth.getAccessToken();
        return await fetchJson(`profile/${id}`, token) as Profile;
    }, [fetchJson]);
}

export const useDeleteProfile = () => {
    
    const genericDeleteApiCall = useGenericDeleteApiCall();
    
    return React.useCallback(async (id: number): Promise<boolean> => {
        const token = await OfficeRuntime.auth.getAccessToken();
        return await genericDeleteApiCall(`profile/${id}`, token);
    }, [genericDeleteApiCall]);
}

export const useUpdateProfile = () => {  
    const { fetchJson } = useFetch();

    return React.useCallback(async ( id: number, data: Partial<NullableOptional<Profile>>): Promise<Profile | undefined> => {
        const dto = {
            name: data.name,
            companyId: data.company === null ? null : data.company?.id,
            locationId: data.location === null ? null : data.location?.id,
            languageId: data.language === null ? null : data.language?.id,
            personName: data.personName,
            personDepartment: data.personDepartment,
            personPhone: data.personPhone,
            personMobile: data.personMobile,
            personFax: data.personFax,
            personEmail: data.personEmail,
        }
    
        // only patch updated properties
        // const transformedDto = transformObject(dto);
        const token = await OfficeRuntime.auth.getAccessToken();
        console.log("token" + token);
        const body = generatePatchDoc(dto);
        return await fetchJson(`profile/${id}`,token, { method: "PATCH", body: JSON.stringify(body) }) as Profile;
    }, [fetchJson]);
} 

export const useCreateProfile = () => {
    const { fetchJson } = useFetch();

    return React.useCallback(async (name: string): Promise<Profile | undefined> => {
        const body = {
            name: name.trim()
        };
        const token = await OfficeRuntime.auth.getAccessToken();
        return await fetchJson(`profile`, token, { method: "POST", body: JSON.stringify(body) }) as Profile;
    }, [fetchJson]);
} 

