/**
 * Executes filtering. Method tries to indicate if filtering should be executed on a single or all columns.
 * @param filterValue
 * @param items
 * @param columns
 */
export function filterList<T>(filterValue: string, listItems: T[], fieldNames: string[]): T[] {
    const filterSeparator = ":";

    let filterColumns = [...fieldNames];
    if (filterValue && filterValue.indexOf(filterSeparator) >= 0) {
        const filteredFieldName = filterValue.split(filterSeparator)[0];
        filterValue = filterValue.split(filterSeparator)[1];

        filterColumns = fieldNames.filter(fieldName => fieldName === filteredFieldName);
    }

    return getFilteredItems<T>(filterValue, listItems, filterColumns);
}

/**
 * Execute filtering on the provided data set and columns
 * @param filterValue
 * @param items
 * @param columns
 */
function getFilteredItems<T>(filterValue: string, listItems: T[], fieldNames: string[]): T[] {
    if (!filterValue) {
        return listItems;
    }

    let result: T[] = [];
    for (const listItem of listItems) {
        let addItemToResultSet = false;
        for (const field of fieldNames) {
            if (doesPropertyContainsValue(listItem, field, filterValue)) {
                addItemToResultSet = true;
                break;
            }
        }

        if (addItemToResultSet) {
            result.push(listItem);
        }
    }

    return result;
}

/**
 * Check if the item contains property with proper value
 * @param item
 * @param property
 * @param filterValue
 */
function doesPropertyContainsValue<T>(item: T, property: string, filterValue: string): boolean {
    const propertyValue = (item as unknown as Record<string, object>)[property];
    let result = false;
    if (propertyValue) {
        // Case insensitive
        result = propertyValue.toString().toLowerCase().indexOf(filterValue.toLowerCase()) >= 0;
    }

    return result;
}