import create from "zustand";
import { LoadingState } from "../../model/LoadingState";
import { User } from "../../model/User";

interface UserState {
    users: User[] | undefined;
    setUsers: (users: User[] | undefined) => void;
    loadingState: LoadingState;
    setLoadingState: (loadingState: LoadingState) => void;
}

export const useUserStore = create<UserState>((set) => ({
    users: undefined,
    loadingState: "NotLoaded",
    setUsers: (users: User[] | undefined) => set(() => ({users: users})),
    setLoadingState: (loadingState: LoadingState) => set(() => ({loadingState: loadingState})),     
}))