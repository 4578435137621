import { DefaultButton, Dialog, DialogFooter, DialogType, IDialogContentProps, IModalProps, PrimaryButton } from "@fluentui/react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { DeleteButton } from "../DeleteButton/DeleteButton";
import useStyles from "./ConfirmationDialog.styles";

export interface IConfirmationDialogProps {
    title?: string;
    onClose: (confirmed: boolean) => void;
    message?: string;
    confirmButtonDanger?: boolean;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function ConfirmationDialog(props: React.PropsWithChildren<IConfirmationDialogProps>) {
    const styles = useStyles();
    const { t: tGeneral } = useTranslation("translation", { keyPrefix: "general" });
    const modalProps: IModalProps = React.useMemo(() => ({
        isBlocking: false,
    }), []);

    const dialogContentProps = React.useMemo(() => ({
        type: DialogType.normal,
        title: props.title,
        closeButtonAriaLabel: 'Close',
        // subText: props.message,
    }) as IDialogContentProps, [props.title]);

    const confirmButton = React.useMemo(() => {
        if (props.confirmButtonDanger){
            return <DeleteButton onClick={() => props.onClose(true)} text={tGeneral("confirm")} />
        }
        return <PrimaryButton onClick={() => props.onClose(true)} text={tGeneral("confirm")} />
    }, [props, tGeneral]);

    return (
        <Dialog
            hidden={false}
            onDismiss={() => props.onClose(false)}
            dialogContentProps={dialogContentProps}
            modalProps={modalProps}
        >
            {props.children}
            {props.message &&
             <div className={styles.body}>{props.message ?? ""}</div>
            }
            <DialogFooter>
                {confirmButton}
                <DefaultButton onClick={() => props.onClose(false)} text={tGeneral("cancel")} />
            </DialogFooter>
        </Dialog>
    );
}