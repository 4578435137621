import create from 'zustand';
import { LoadingState } from '../../model/LoadingState';
import { RoleType } from '../../model/RoleType';
import { User } from '../../model/User';

interface SessionState {
    currentUser: User | undefined;
    loadingState: LoadingState;
    setCurrentUser: (user: User) => void;
    hasRole: (role: RoleType) => boolean;
    setLoadingState: (state: LoadingState) => void;
}

export const useSessionStore = create<SessionState>((set, get) => ({
    currentUser: undefined,
    loadingState: "NotLoaded",
    setCurrentUser: (user: User) => set(() => ({currentUser: user})),
    setLoadingState: (state: LoadingState) =>  set(() => ({loadingState: state})),
    hasRole: (role: RoleType) => {
        if(get().currentUser == null){
            return false;
        }
        return  get().currentUser?.userRoles.find(r => r.roleId === role) != null;
    }
}))