import { BaseButton, Button, Icon, IIconStyleProps, IIconStyles, IStyleFunctionOrObject } from "@fluentui/react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import NewLanguageNameDialog from "../../../components/NameDialogs/NewLanguageNameDialog/NewLanguageNameDialog";
import { useToasts } from "../../../hooks/useToasts";
import useTranslateLanguage from "../../../hooks/useTranslateLanguage";
import { Location } from "../../../model/Location";
import { useAdminApi } from "../../api/useAdminApi";
import useSetCurrentCompanyItem from "../../hooks/useSetCurrentCompanyItem";
import { useAdminStore } from "../../state/useAdminState";

export interface AddLanguageButtonProps {
    location?: Location;
}



// eslint-disable-next-line @typescript-eslint/naming-convention
export const AddLanguageButton = (props: AddLanguageButtonProps) => {
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const { t } = useTranslation("translation");
    const setCurrentCompanyItem = useSetCurrentCompanyItem();
    const api = useAdminApi();
    const store = useAdminStore();
    const { translateLanguageCode, translateLanguage } = useTranslateLanguage();
    const toasts = useToasts();
    const iconStyles: IStyleFunctionOrObject<IIconStyleProps, IIconStyles> | undefined = React.useMemo(() => {
        return {
            root: {
                // background: "red",
                paddingLeft: "3px",
                paddingRight: "3px",
                "&:hover": {
                    background: "#e7e7e7",
                },
                "&:focus": {
                    background: "#e7e7e7",
                },
                "&:active": {
                    background: "#e7e7e7",
                },
    
            },
        }
    }, []);

    const onAddClick = React.useCallback((ev: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement, MouseEvent>) => {
        // prevent navLink to also be clicked
        ev.stopPropagation();
        // todo spawn add dialog
        setShowModal(true);
    }, []);

    const onCloseModal = async (confirmed: boolean, isoCode?: string | undefined) => {
        setShowModal(false);
        if (!confirmed) return;
        if (!isoCode || !props.location?.id || !props.location.companyId) return toasts.createFailure(`${t("general.profile.language")} '${translateLanguageCode(isoCode??"")}'`);
        let result = await api.createLanguage(props.location.id, isoCode);
        if (!result) return toasts.createFailure(`${t("general.profile.language")} '${translateLanguageCode(isoCode)}'`);
        result = translateLanguage(result);

        store.addLanguage(props.location.companyId, props.location.id, result);
        setCurrentCompanyItem({type: "LocationLanguage", item: result});
        store.setAddedCompanyItem({type: "LocationLanguage", item: result});
        toasts.createSuccess(`${t("general.profile.language")} '${result.name}'`)
    }
    
    return (
        <>
        <Icon
            iconName="Add"
            styles={iconStyles}
            onClick={(ev) => onAddClick(ev)}
        />
        {showModal &&
            <NewLanguageNameDialog 
                excludedIsoCodes={props.location?.languages?.map(l => l.isoCode ?? "") ?? []}
                onClose={onCloseModal}
            />
        }
        </>
    );
};