import { MessageBar, Text, TextField } from "@fluentui/react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Location } from "../../../../model/Location";
import { undefinedIfEmpty } from "../../../../utils/undefinedIfEmpty";
import { useAdminApi } from "../../../api/useAdminApi";
import { useAdminStore } from "../../../state/useAdminState";


export interface CompaniesPageEditLocationViewProps {
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const CompaniesPageEditLocationView = (props: CompaniesPageEditLocationViewProps) => {
    const { t } = useTranslation("translation");
    const store = useAdminStore();
    const api = useAdminApi();
    const entityToShow = React.useMemo(() => {
        if (store.currentCompanyItem?.type === "Location"){
            return { ...store.currentCompanyItem?.item, ...store.editedCompanyItem?.item } as Location
        }
    }, [store.currentCompanyItem?.item, store.currentCompanyItem?.type, store.editedCompanyItem?.item]);

    React.useEffect(() => {
        const determineProfiles = async () => {
            if (!store.currentCompanyItem?.item || store.currentCompanyItem.item.quantityDependentProfiles !== undefined) return;
            const count = await api.getLocationDependentProfileCount(store.currentCompanyItem?.item.id ?? -1);
            const location = store.currentCompanyItem.item;
            location.quantityDependentProfiles = count;
            store.updateLocation(location);
        }
        void determineProfiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.currentCompanyItem?.item.id]);

    return (
        <div>
            <Text 
                variant="xLargePlus">{t("companiesPageEditLocationView.title")}
            </Text>
            { (store.currentCompanyItem?.item.quantityDependentProfiles ?? 0) > 0 &&
                <MessageBar>
                    {t("general.dependentProfileCount", {n: store.currentCompanyItem?.item.quantityDependentProfiles})}
                </MessageBar>
            }
            <TextField 
                styles={{root: {marginTop: "1em"}}} 
                label={t("general.location.name")}
                value={entityToShow?.name ?? ""}
                onChange={(_ev, newValue) => store.patchEditedCompanyItem<Location>({name: undefinedIfEmpty(newValue) ?? null})}
                >
            </TextField>
        </div>
    );
};