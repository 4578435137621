import { DefaultButton, IButtonStyles } from "@fluentui/react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import NewCompanyNameDialog from "../../../components/NameDialogs/NewCompanyNameDialog/NewCompanyNameDialog";
import { useToasts } from "../../../hooks/useToasts";
import { RoleType } from "../../../model/RoleType";
import { useAdminApi } from "../../api/useAdminApi";
import useSetCurrentCompanyItem from "../../hooks/useSetCurrentCompanyItem";
import { useAdminStore } from "../../state/useAdminState";
import { useSessionStore } from "../../state/useSessionStore";

export interface AddCompanyButtonProps {
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const AddCompanyButton = (props: AddCompanyButtonProps) => {
    const { t: tGlobal } = useTranslation("translation");
    const { t } = useTranslation("translation", { keyPrefix: "addCompanyButton" });
    const setCurrentCompanyItem = useSetCurrentCompanyItem();
    const toasts = useToasts();
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const api = useAdminApi();
    const store = useAdminStore();
    const { hasRole } = useSessionStore();
    const buttonStyles = React.useMemo(() => {
        return {
            root: {
                border: "none"
            }
        } as IButtonStyles
    }, []);

    const onCloseModal = React.useCallback(async (confirmed: boolean, name?: string | undefined) => {
        setShowModal(false);
        if (!confirmed) return;
        if (!name) return toasts.createFailure(`${tGlobal("general.profile.company")} '${name}`);
        const result = await api.createCompany(name);
        if (!result) return toasts.createFailure(`${tGlobal("general.profile.company")} '${name}`);
        store.setCompanies([...(store.companies ?? []), result]);
        setCurrentCompanyItem({ type: "Company", item: result });
        store.setAddedCompanyItem({ type: "Company", item: result });
        toasts.createSuccess(`${tGlobal("general.profile.company")} '${name}`);
    }, [api, setCurrentCompanyItem, store, tGlobal, toasts]);

    const showButton = hasRole(RoleType.GlobalAdmin);

    return (
        <>
            {showButton === true && <DefaultButton
                styles={buttonStyles}
                iconProps={{ iconName: "Add" }}
                onClick={() => setShowModal(true)}
            >
                {t("label")}

            </DefaultButton>
            }
            {showModal &&
                <NewCompanyNameDialog onClose={onCloseModal}></NewCompanyNameDialog>
            }
        </>
    );
};