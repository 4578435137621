import React from "react";
import { Location } from "../model/Location";
import { useFetch } from "./fetch";

export const useGetLocations = () => {
    const { fetchJson } = useFetch();

    return React.useCallback(async (companyId: number, filter?: string): Promise<Location[] | undefined> => {
        const token = await OfficeRuntime.auth.getAccessToken();
        const params = new Map([["companyId", companyId.toString()]]);
        if (filter) params.set("name", filter)
        return (await fetchJson("locations", token, { params: params })) as Location[];
    }, [fetchJson]);
}
export const useGetLocation = () => {
    const { fetchJson } = useFetch();

    return React.useCallback(async ( id: number): Promise<Location | undefined> => {
        const token = await OfficeRuntime.auth.getAccessToken();
        return await fetchJson(`location/${id}`, token) as Location;
    }, [fetchJson]);
}
