import React from "react";
import { useGetCompanies, useGetCompany } from "./companiesApi";
import { useGetLanguage, useGetLanguages } from "./languagesApi";
import { useGetLocation, useGetLocations } from "./locationsApi";
import { useCreateProfile, useDeleteProfile, useGetProfile, useGetProfiles, useUpdateProfile } from "./profilesApi";
import { useDownloadTemplate, useGetTemplate, useGetTemplates } from "./templatesApi";

export const useApi = () => {

    const getProfiles = useGetProfiles();
    const getProfile = useGetProfile();
    const deleteProfile = useDeleteProfile();
    const updateProfile = useUpdateProfile()
    const createProfile = useCreateProfile();

    const getCompanies = useGetCompanies();
    const getCompany = useGetCompany();
    const deleteCompany = useGetCompany();
    const getLanguages = useGetLanguages();
    const getLanguage = useGetLanguage();
    const getLocations = useGetLocations();
    const getLocation = useGetLocation();

    const downloadTemplate = useDownloadTemplate();

    const getTemplates = useGetTemplates();
    const getTemplate = useGetTemplate();

    const api = React.useMemo(() => {
        return {
            getProfiles,
            getProfile,
            deleteProfile,
            updateProfile,
            createProfile,
            getCompanies,
            getCompany,
            deleteCompany,
            getLanguage,
            getLanguages,
            getLocation,
            getLocations,
            downloadTemplate,
            getTemplates,
            getTemplate,
        }
    }, [getLanguage, createProfile, deleteCompany, deleteProfile, getCompanies, getCompany, getLanguages, getLocation, getLocations, getProfile, getProfiles, downloadTemplate, getTemplate, getTemplates, updateProfile])
    return api;
}
