import { SelectionMode } from "@fluentui/react/lib/Selection";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToasts } from "../../../hooks/useToasts";
import { DisplayUser } from "../../../model/DisplayUser";
import { RoleType } from "../../../model/RoleType";
import { User } from "../../../model/User";
import { filterList } from "../../../utils/filterList";
import { useAdminApi } from "../../api/useAdminApi";
import { useSessionStore } from "../../state/useSessionStore";
import { useUserStore } from "../../state/useUsersStore";
import { ButtonConfiguration, GenericList, IFlexibleColumn } from "../GenericList/GenericList";
import { UserEditModal } from "../UserEditModal/UserEditModal";
import useStyles from "./UserList.styles";


export interface UserListProps {

}

export const UserList = (props: UserListProps): JSX.Element => {
    const toasts = useToasts();
    const { loadingState, users, setLoadingState, setUsers } = useUserStore();
    const { getUsers, deleteUser } = useAdminApi();
    const { t } = useTranslation("translation", { keyPrefix: "usersPage" });
    const { t: tr } = useTranslation("translation", { keyPrefix: "roles" });
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
    const [isUserModalVisible, setIsUserModalVisible] = useState<boolean>(false);
    const [selectedUsers, setSelectedUsers] = React.useState<DisplayUser[]>([]);
    const styles = useStyles();
    const { currentUser, hasRole } = useSessionStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getRoleNameByRoleType = React.useCallback((id: RoleType | undefined) => {
        let roleName = "";
        switch (id) {
            case RoleType.GlobalAdmin:
                roleName = tr("globalAdmin");
                break;
            case RoleType.CompanyAdmin:
                roleName = tr("companyAdmin");
                break;
            case RoleType.User:
                roleName = tr("user");
                break;
            default:
        }
        return roleName;
    }, [tr]);

    const loadUsers = useCallback(async () => {
        setLoadingState("Loading")
        const users = await getUsers();
        if (users) {
            setUsers(users);
            setLoadingState("Loaded")

        } else {
            setLoadingState("Failure")
        }

    }, [getUsers, setLoadingState, setUsers]);

    useEffect(() => {
        if (loadingState === "NotLoaded") {
            loadUsers();
        }
    }, [loadUsers, loadingState]);


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const columns: IFlexibleColumn[] = React.useMemo(() => {
        return [
            {
                key: 'id',
                name: t("columns.id"),
                fieldName: 'id',
                minWidth: 16,
                maxWidth: 100,
            },
            {
                key: 'name',
                name: t("columns.name"),
                fieldName: 'displayName',
                minWidth: 100,
                maxWidth: 300,
            },
            {
                key: 'role',
                name: t("columns.role"),
                fieldName: 'role',
                minWidth: 16,
                maxWidth: 100,
                onRender: (item: DisplayUser) => {
                    return (<span>{item.role}</span>);
                }
            }
        ];
    }, [t]);

    const displayedUsers = useMemo(() => {
        const newUsers = users?.map((value) => {
            const id = (value.userRoles && value.userRoles.length > 0) ? value.userRoles[0].roleId : undefined;
            const roleName = getRoleNameByRoleType(id);
            return {
                id: value.id,
                displayName: value.displayName,
                role: roleName,
                userObject: value
            } as DisplayUser
        })
        const filteredUsers = searchTerm ? filterList(searchTerm, newUsers ?? [], columns.map(c => c.fieldName)) : newUsers;
        return filteredUsers ?? [];
    }, [columns, getRoleNameByRoleType, searchTerm, users]);

    const onItemInvoked = useCallback((item: DisplayUser) => {
        const user = users?.find(u => u.id === item.id);
        setSelectedUser(user);
        if (user) {
            setIsUserModalVisible(true);
        }
    }, [users]);

    const onChangeSearch = useCallback((value: string | undefined) => {
        setSearchTerm(value);
    }, []);

    const onDismiss = useCallback(() => {
        setIsUserModalVisible(false);
        setSelectedUser(undefined);
    }, []);

    const onSave = useCallback((user: User | undefined) => {
        if (user) {
            const newArray = users?.map(entry => entry.id === user.id ? user : entry);
            if (!selectedUser) {
                newArray?.push(user);
            }
            setUsers(newArray);
            toasts.updateSuccess();
        }
    }, [selectedUser, setUsers, toasts, users]);

    const onDelete = useCallback(async (user: User | undefined) => {
        onDismiss();
        if (user) {
            const deletedUser = await deleteUser(user.id);
            if(deletedUser){
                const newArray = users?.filter(entry => entry.id !== user.id);
                setUsers(newArray);
                toasts.deleteSuccess();
            } else {
                toasts.deleteFailure();
            }
        }
    }, [deleteUser, onDismiss, setUsers, toasts, users]);

    const newButton: ButtonConfiguration = React.useMemo(() => {
        return {
            onClick: () => {
                setSelectedUser(undefined);
                setIsUserModalVisible(true);
            },
            visible: hasRole(RoleType.GlobalAdmin)
            
        }
    }, [hasRole]);

    const editButton: ButtonConfiguration = React.useMemo(() => {
        return {
            onClick: () => {
                if (selectedUsers.length === 1) {
                    onItemInvoked(selectedUsers[0]);
                }
            },
            visible: hasRole(RoleType.GlobalAdmin)
        }
    }, [hasRole, onItemInvoked, selectedUsers]);

    return (
        <>
            <GenericList<DisplayUser>
                onChangeSearch={onChangeSearch}
                styles={styles}
                columns={columns}
                displayedItems={displayedUsers}
                selectionMode={SelectionMode.multiple}
                onItemInvoked={onItemInvoked}
                onSelectionChanged={(items) => setSelectedUsers(items ?? [])}
                editButton={editButton}
                newButton={newButton}
            />
            <UserEditModal isOpen={isUserModalVisible} onDismiss={onDismiss} onSave={onSave} onDelete={onDelete} selectedUser={selectedUser} />
        </>
    );
}