import * as React from "react";
import { useTranslation } from "react-i18next";
import NewEntityNameDialog from "../NewEntityNameDialog/NewEntityNameDialog";

export interface INewCompanyNameDialogProps {
    onClose: (confirmed: boolean, name?: string) => void;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function NewCompanyNameDialog(props: INewCompanyNameDialogProps) {
    const { t } = useTranslation("translation", { keyPrefix: "newCompanyNameDialog" });

    return (
        <NewEntityNameDialog 
            title={t("title")}
            message={t("message")}
            onClose={props.onClose} />
    );
}