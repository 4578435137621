import { useMsal } from "@azure/msal-react";
import { ActionButton } from "@fluentui/react/lib/Button";
import { IIconProps } from "@fluentui/react/lib/Icon";
import * as React from 'react';
import { useTranslation } from "react-i18next";
import { useMonitoringStore } from "../../../state/useMonitoringStore";
import { loginRequest } from "../../authConfig";
import useStyles from "./SignInButton.styles";
/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInButton = () => {
    const { instance } = useMsal();
    const { t } = useTranslation("translation", { keyPrefix: "signInButton" });
    const styles = useStyles();
    const logger = useMonitoringStore();
    const handleLogin = () => {
        try {
            instance.loginRedirect(loginRequest);
        } catch (error) {
            logger.logError("Error occured during login redirect:" + error);
        }

            // instance.loginPopup(loginRequest).catch(e => {
            //     logger.logError(e);
            // })
    }
    const loginIcon: IIconProps = {
        iconName: 'contact',
        className: styles.icon
    };
    return (
        <ActionButton
            className={styles.button}
            onClick={() => handleLogin()}
            iconProps={loginIcon}
        >
            {t("loginButton")}
        </ActionButton>

    );
}