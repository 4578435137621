import React from "react";
import { useFetch } from "../../api/fetch";
import { Template } from "../../model/Template";
import { useTokenApi } from "./tokenApi";


export const useGetTemplates = () => {
    const { getToken } = useTokenApi();
    const { fetchJson } = useFetch();
    
    return React.useCallback(async (): Promise<Template[] | undefined> => {
        const token = await getToken();
        
        return await fetchJson("templates", token) as Template[];
    }, [fetchJson, getToken]);
}

export const useGetTemplate = () => {
    const { getToken } = useTokenApi();
    const { fetchJson } = useFetch();

    return React.useCallback(async (id: number): Promise<Template | undefined> => {
        const token = await getToken();

        return await fetchJson(`template/${id}`, token) as Template;
    }, [fetchJson, getToken]);
}

export const useUpdateTemplate = () => {
    const { getToken } = useTokenApi();
    const { fetchJson } = useFetch();

    return React.useCallback(async (id: number, data: FormData): Promise<Template | undefined> => {
        const token = await getToken();
        // only patch updated properties


        return await fetchJson(`template/${id}`, token, { method: "PATCH", body: data }) as Template;
    }, [fetchJson, getToken]);
}

export const useCreateTemplate = () => {
    const { getToken } = useTokenApi();
    const { fetchJson } = useFetch();

    return React.useCallback(async (data: FormData): Promise<Template | undefined> => {
        const token = await getToken();
        return await fetchJson(`template`, token, { method: "POST", body: data }) as Template;
    }, [fetchJson, getToken]);
}

export const useDeleteTemplate = () => {
    const { getToken } = useTokenApi();
    const { fetchJson } = useFetch();

    return React.useCallback(async (id: number): Promise<Template | undefined> => {
        const token = await getToken();
        return await fetchJson(`template/${id}`, token, { method: "DELETE" }) as Template;
    }, [fetchJson, getToken]);
}

export const useDownloadTemplate = () => {
    const { getToken } = useTokenApi();
    const { fetchBlob } = useFetch();

    return React.useCallback(async (id: number): Promise<File | undefined> => {
        const token = await getToken();

        return await fetchBlob(`template/download/${id}`, token, { method: "GET", returnType: "blob", isFile: true }) as File;
    }, [fetchBlob, getToken]);
}
