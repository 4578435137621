import { makeStyles } from "@fluentui/react";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let style = makeStyles((theme) => ({
        cancelButton: {
            margin: "0px 0px 0px 50vh"
        },
        saveButton: {
            margin: "0px 0px 0px 10px"
        },
        buttonRow: {
            display: "flex",
            flexDirection: "row",
            padding: "15px 0px 0px 0px"
        },
        flexWrapper: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flexBasis: "100%",
            minHeight: "100%",
            padding: "15px"
        }
    }));
    return style();
};