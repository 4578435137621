import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import addDE from "./de";
import addEN from "./en";

export const initI18n = () => {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      // the translations
      // (tip move them in a JSON file and import them,
      // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
      resources: {},
      // eslint-disable-next-line no-undef
      lng: navigator.language || "en",
      fallbackLng: "en",
    });
  addDE();
  addEN();
};
