import { makeStyles } from "@fluentui/react";
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {

    let style = makeStyles((theme) => ({
        container: {
            display: "flex",
            alignItems: "center"        },
        fileName: {
            display: "flex",
            
        },
        fileIcon: {
            marginRight: "10px"        },
        spinner: {
            marginLeft: "5px"
        }
    }));
    return style();
};