import * as React from "react";
import { useFetch } from "./fetch";

const useGenericDeleteApiCall = () => {
    const { fetchBoolean } = useFetch();
    return React.useCallback(async (url: string, accessToken: string, ) => {
        return await fetchBoolean(url, accessToken, { method: "DELETE"}) as boolean;
    }, [fetchBoolean]);
    
}

export default useGenericDeleteApiCall;