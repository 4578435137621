import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const useToasts = () => {
    const { t } = useTranslation("translation", { keyPrefix: "general.toasts"});

    const updateSuccess = React.useCallback(() => {
        toast.success(t("update.success"));
    }, [t]);

    const updateFailure = React.useCallback(() => {
        toast.error(t("update.fail"));
    }, [t]);

    const deleteSuccess = React.useCallback(() => {
        toast.success(t("delete.success"));
    }, [t]);

    const deleteFailure = React.useCallback(() => {
        toast.error(t("delete.fail"));
    }, [t]);

    const createSuccess = React.useCallback((entity: string) => {
        const msg = `${t("create.success", {entity: entity, interpolation: {escapeValue: false}})}`
        toast.success(msg);
    }, [t]);

    const createFailure = React.useCallback((entity: string) => {
        const msg = `${t("create.fail", {entity: entity, interpolation: {escapeValue: false}})}`
        toast.error(msg);
    }, [t]);

    const templateRetrievalFailure = React.useCallback(() => {
        const msg = `${t("templateRetrieve.fail")}`
        toast.error(msg);
    }, [t]);

    const templateReplacementSuccess = React.useCallback(() => {
        const msg = `${t("templateReplacement.success")}`
        toast.success(msg);
    }, [t]);

    const templateReplacementFailure = React.useCallback(() => {
        const msg = `${t("templateReplacement.fail")}`
        toast.error(msg);
    }, [t]);
    
    return {
        updateSuccess,
        updateFailure,
        deleteSuccess, 
        deleteFailure,
        createSuccess,
        createFailure,
        templateRetrievalFailure,
        templateReplacementSuccess,
        templateReplacementFailure
    };
}