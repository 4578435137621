import { makeStyles } from "@fluentui/react";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let style = makeStyles((theme) => ({
        titleBar: {
            display: "flex",
            justifyContent: "space-between",
            height: "50px",
        },
        rightAlignWrapper: {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            height: "100%",
            marginRight: "32px",
        }

    }));
    return style();
};