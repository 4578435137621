import React from "react";
import { UserList } from "../../components/UserList/UserList";


export interface UsersPageProps {

}


export const UsersPage = (props: UsersPageProps): JSX.Element => {
    return (<>{<UserList />}</>);
}