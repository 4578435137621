import { NullableOptional } from "../model/nullable";

/**
 * removes undefined properties and trim string properties
 */
const transformObject = <T extends object>(element: T): Partial<T> => {
    const obj = { ...element };
    Object.keys(obj).forEach(key => {
        let element = (obj as any)[key];
        if (element === undefined) {
            delete (obj as any)[key]
        }
        else if (typeof element === "string") {
            (obj as any)[key] = element.trim()
        }
    });
    return obj;
}


const buildPatchDoc = <T extends object>(data: Partial<NullableOptional<T>>) => {
    const body: { op: string; path: string; value?: string }[] = [];
    Object.keys(data).forEach(key => {
        const value = (data as any)[key];
        const op = value === null ? "remove" : "replace";
        const patchAction = { op: op, path: `/${key}`, value: op === "replace" ? value : undefined };
        // if (op === "replace") patchAction.value = value;
        body.push(patchAction);
    })
    return body;
}

export const generatePatchDoc = <T extends object>(object: T) => {
    const t = transformObject(object);
    return buildPatchDoc(t);
}
