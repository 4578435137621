import { BaseButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { ComboBox, IComboBoxOption } from "@fluentui/react/lib/ComboBox";
import { Modal } from "@fluentui/react/lib/Modal";
import { TextField } from "@fluentui/react/lib/TextField";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToasts } from "../../../hooks/useToasts";
import { CategoryType } from "../../../model/CategoryType";
import { undefinedIfEmpty } from "../../../utils/undefinedIfEmpty";
import { useAdminApi } from "../../api/useAdminApi";
import { TemplateDropzone } from "../TemplateDropzone/TemplateDropzone";
import useStyles from "./TemplateNewModal.styles";



export interface TemplateNewModalProps {
    isOpen: boolean;
    onDismiss: () => void;
    // onSave: (template: Template | undefined) => void;
    // onDelete: (template: Template | undefined) => void;
}

export const TemplateNewModal = (props: TemplateNewModalProps): JSX.Element => {
    const { t } = useTranslation("translation", { keyPrefix: "templateNewModal" });
    const { t: tr } = useTranslation("translation", { keyPrefix: "templateCategory" });
    const { t: tu } = useTranslation("translation", { keyPrefix: "templateButtons" });

    const [currentName, setCurrentName] = useState<string | undefined>(undefined);
    const [selectedCategory, setSelectedCategory] = useState<CategoryType | undefined>(undefined);
    const adminApi = useAdminApi();
    const styles = useStyles();
    const [templateFile, setTemplateFile] = useState<File | undefined>(undefined);
    const toasts = useToasts();

    const areControlsDisabled = useMemo(() => {
        return !undefinedIfEmpty(currentName, true) || selectedCategory == null || templateFile == null;
    }, [currentName, selectedCategory, templateFile]);

    const categoryOptions: IComboBoxOption[] = [
        {
            key: CategoryType.Letter,
            text: tr("letter"),
        },
        {
            key: CategoryType.Fax,
            text: tr("fax"),
        },
        {
            key: CategoryType.Other,
            text: tr("other"),
        },
    ]

    const onDismiss = useCallback(() => {
        props.onDismiss();
        setCurrentName(undefined);
        setSelectedCategory(undefined);

    }, [props]);



    const onSave = useCallback(async () => {
        var data = new FormData();
        if (currentName) data.append("name", currentName);
        if (selectedCategory) {
            data.append("category", selectedCategory.toString());
        }
        if (templateFile) {
            data.append("templateFile", templateFile, templateFile.name);
        }
        const addedTemplate = await adminApi.createTemplate(data);
        if (addedTemplate != null) {
            if (currentName) toasts.createSuccess(currentName);
        }
        else {
            if (currentName) toasts.createFailure(currentName);
        }
        onDismiss();

    }, [currentName, selectedCategory, templateFile, adminApi, onDismiss, toasts]);


    return (
        <div>
            <Modal
                isBlocking={true}
                isOpen={props.isOpen}
                onDismiss={onDismiss}
                layerProps={{eventBubblingEnabled: true}}
            >
                <div className={styles.flexWrapper}>
                    <div>
                        <h2>{t("header")}</h2>
                    </div>
                    <div>
                        <TextField label={t("name")} value={currentName} onChange={(event, newValue) => setCurrentName(newValue)} required></TextField>
                        <ComboBox
                            label={t("category")}
                            options={categoryOptions}
                            selectedKey={selectedCategory}
                            onChange={(event, option) => setSelectedCategory(option?.key as CategoryType ?? undefined)}
                            required
                        />
                    </div>
                    <div>
                        <TemplateDropzone setTemplateFile={setTemplateFile} templateFileId={undefined} templateFileName={undefined}></TemplateDropzone>
                    </div>
                    <div className={styles.buttonRow}>
                        <BaseButton className={styles.cancelButton} onClick={onDismiss}>{tu("cancelButton")}</BaseButton>
                        <PrimaryButton className={styles.saveButton} onClick={onSave} disabled={areControlsDisabled}>{tu("saveButton")}</PrimaryButton>
                    </div>
                </div>

            </Modal >
        </div >
    )
}


