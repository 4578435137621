import React from "react";
import { Template } from "../model/Template";
import { useFetch } from "./fetch";

export const useDownloadTemplate = () => {
    const { fetchBlob } = useFetch();
    return React.useCallback(async (id: number): Promise<Blob | undefined> => {
      const token = await OfficeRuntime.auth.getAccessToken();
        return await fetchBlob(`template/download/${id}`, token);
    }, [fetchBlob])
}

export const useGetTemplates = () => {
    const { fetchJson } = useFetch();
    return React.useCallback(async (filter?: string): Promise<Template[] | undefined> => {
      const token = await OfficeRuntime.auth.getAccessToken();
      const params = filter ? new Map([["name", filter]]) : undefined;
      const result = (await fetchJson("templates", token, { params: params })) as Template[];
      return result;
    }, [fetchJson]);
}

export const useGetTemplate = () => {
    const { fetchJson } = useFetch();
    return React.useCallback(async (id: number): Promise<Template | undefined> => {
      const token = await OfficeRuntime.auth.getAccessToken();
      return (await fetchJson(`template/${id}`, token)) as Template;
    }, [fetchJson]);
}
