import React from "react";
import useTranslateLanguage from "../hooks/useTranslateLanguage";
import { Company } from "../model/Company";
import { useFetch } from "./fetch";

export const useGetCompanies = () => {
  
  const { translateLanguage } = useTranslateLanguage()
  const { fetchJson } = useFetch();

  return React.useCallback(async (filter?: string): Promise<Company[] | undefined> => {
    const token = await OfficeRuntime.auth.getAccessToken();
    const params = filter ? new Map([["name", filter]]) : undefined;
    const result = (await fetchJson("companies", token, { params: params })) as Company[];
    if (result) result.forEach(x => x.locations?.forEach(y => y.languages?.forEach((z, i) => {
      if (z && y.languages) y.languages[i] = translateLanguage(z);
    })))
    return result;
  }, [fetchJson, translateLanguage]);
}

export const useGetCompany = () => {
  const { fetchJson } = useFetch();

  return React.useCallback(async (id: number): Promise<Company | undefined> => {
    const token = await OfficeRuntime.auth.getAccessToken();
    return (await fetchJson(`company/${id}`, token)) as Company;
  }, [fetchJson]);
}
