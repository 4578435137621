import { Stack } from "@fluentui/react";
import * as React from "react";
import { Company } from "../../../model/Company";
import { RoleType } from "../../../model/RoleType";
import { useAdminApi } from "../../api/useAdminApi";
import { AddCompanyButton } from "../../components/AddCompanyButton/AddCompanyButton";
import { CompanyTreeView } from "../../components/CompanyTreeView/CompanyTreeView";
import { useAdminStore } from "../../state/useAdminState";
import { useSessionStore } from "../../state/useSessionStore";
import { CompaniesPageEditView } from "./CompaniesPageEditView/CompaniesPageEditView";
// import useStyles from "./CompaniesPage.style";

export interface CompaniesPageProps {
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const CompaniesPage = (props: CompaniesPageProps) => {
    // const styles = useStyles();
    const { getCompanies } = useAdminApi();
    const { setCompanies } = useAdminStore();
    const { currentUser } = useSessionStore();
    React.useEffect(() => {
        const fetchCompanies = async () => {
            
            let result: Company[] | undefined = await getCompanies();
            
            const companyId = currentUser?.userRoles.find((u) => u.roleId === RoleType.CompanyAdmin)?.companyId;
            if(companyId){
                result = result?.filter((c)=> c.id === companyId);
            }
            result?.flatMap(x => x.locations).forEach(y => {
                if (!y?.languages) return;
                y.languages = y?.languages?.map(z => ({ ...z, isLoaded: false }));
            })
            setCompanies(result);
        }
        void fetchCompanies();
    }, [currentUser?.userRoles, getCompanies, setCompanies]);

    return (
        
            <Stack horizontal tokens={{ childrenGap: 10, padding: 10 }}>
                <Stack.Item>
                    <Stack styles={{ root: { borderRight: "1px solid rgba(0,0,0,0.2)" } }}>
                        <Stack.Item styles={{ root: { paddingBottom: 8, borderBottom: "1px solid rgba(0,0,0,0.2)" } }} >
                            <AddCompanyButton></AddCompanyButton>
                        </Stack.Item>
                        <Stack.Item>
                            <CompanyTreeView></CompanyTreeView>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
                <Stack.Item styles={{ root: { width: "100%" } }}>
                    <CompaniesPageEditView></CompaniesPageEditView>
                </Stack.Item>
            </Stack>
        
    );
};