import * as React from "react";
import useStyles from "./Tile.style";

export interface TileProps {
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const Tile = (props: React.PropsWithChildren<TileProps>) => {
    const styles = useStyles();
    
    return (
        <div className={styles.root}>
            {props.children}
        </div>
    );
};