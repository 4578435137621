import { DefaultButton, MessageBar, MessageBarType, PrimaryButton, Stack } from "@fluentui/react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import ConfirmationDialog from "../../../../components/ConfirmationDialog/ConfirmationDialog";
import { DeleteButton } from "../../../../components/DeleteButton/DeleteButton";
import { useToasts } from "../../../../hooks/useToasts";
import { Company } from "../../../../model/Company";
import { Location } from "../../../../model/Location";
import { LocationLanguage } from "../../../../model/LocationLanguage";
import { useAdminApi } from "../../../api/useAdminApi";
import { useAdminStore } from "../../../state/useAdminState";
import { CompaniesPageEditCompanyView } from "../CompaniesPageEditCompanyView/CompaniesPageEditCompanyView";
import { CompaniesPageEditLanguageView } from "../CompaniesPageEditLanguageView/CompaniesPageEditLanguageView";
import { CompaniesPageEditLocationView } from "../CompaniesPageEditLocationView/CompaniesPageEditLocationView";
// import useStyles from "./CompanyEditView.style";

export interface CompaniesPageEditViewProps {
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const CompaniesPageEditView = (props: CompaniesPageEditViewProps) => {
    // const styles = useStyles();
    const store = useAdminStore();
    const api = useAdminApi();
    const { t } = useTranslation("translation", {keyPrefix: "companiesPageEditView"});
    const { t: tGlobal } = useTranslation("translation");
    const toasts = useToasts();
    const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState<boolean>(false);
    const editView = React.useMemo(() => {
        if (store.currentCompanyItem?.type === "Company") {
            return <CompaniesPageEditCompanyView />
        }
        else if (store.currentCompanyItem?.type === "Location") {
            return <CompaniesPageEditLocationView />
        }
        else if (store.currentCompanyItem?.type === "LocationLanguage") {
            return <CompaniesPageEditLanguageView />
        }
        return <></>;
    }, [store.currentCompanyItem?.type]);

    const onClickCancel = React.useCallback(() => store.resetEditedCompanyItem(), [store]);
    const noChangesMade = React.useMemo(() => !store.editedCompanyItem?.item || Object.keys(store.editedCompanyItem.item).filter(k => k !== "id").length === 0, [store.editedCompanyItem]);

    const onClickSave = React.useCallback(async () => {
        if (!store.editedCompanyItem || !store.companies) return;
        if (store.editedCompanyItem.type === "Company") {
            const result = await api.updateCompany(store.editedCompanyItem.item.id, store.editedCompanyItem.item);
            if (result) {
                store.updateCompany(result);
            }
        }
        else if (store.editedCompanyItem.type === "Location") {
            const result = await api.updateLocation(store.editedCompanyItem.item.id, store.editedCompanyItem.item);
            if (result) {
                store.updateLocation(result);
            }
        }
        else if (store.editedCompanyItem.type === "LocationLanguage") {
            const result = await api.updateLanguage(store.editedCompanyItem.item.id, store.editedCompanyItem.item);
            if (result) {
                store.updateLanguage(result);
            }
        }
    }, [api, store]);

    const deleteCompany = React.useCallback(async () => {
        const item = store.currentCompanyItem?.item as Company;
        const result = await api.deleteCompany(item.id);
        if (result) {
            store.deleteCompany(item.id);
        }
        return result;
    }, [api, store]);

    const deleteLocation = React.useCallback(async () => {
        const item = store.currentCompanyItem?.item as Location;
        const result = await api.deleteLocation(item.id);
        if (result) {
            store.deleteLocation(item.id, item.companyId ?? -1);
        }
        return result;
    }, [api, store]);

    const deleteLanguage = React.useCallback(async () => {
        const item = store.currentCompanyItem?.item as LocationLanguage;
        const result = await api.deleteLanguage(item.id);
        if (result) {
            store.deleteLanguage(item.id, item.locationId ?? -1)
        }
        return result;
    }, [api, store]);

    const onCloseConfirmationDialog = React.useCallback(async (confirmed: boolean) => {
        setShowDeleteConfirmation(false);
        if (!confirmed || !store.currentCompanyItem?.item) return;
        let deleteFunction = undefined;
        if (store.currentCompanyItem?.type === "Company") deleteFunction = deleteCompany;
        else if (store.currentCompanyItem?.type === "Location") deleteFunction = deleteLocation;
        else if (store.currentCompanyItem?.type === "LocationLanguage") deleteFunction = deleteLanguage;
        else return;
        const result = await deleteFunction();
        if (result) toasts.deleteSuccess();
        else toasts.deleteFailure();
    }, [deleteCompany, deleteLanguage, deleteLocation, store.currentCompanyItem?.item, store.currentCompanyItem?.type, toasts]);

    const confirmationTranslation: { message: string, title: string } = React.useMemo(() => {
        const name = store.currentCompanyItem?.item.name;
        const dependentProfiles =  (store.currentCompanyItem?.item.quantityDependentProfiles ?? 0) > 0 
            ? ` ${t("confirmationMessages.dependentProfiles")}`
            : "";
        if (store.currentCompanyItem?.type === "Company") {
            return {
                message: t("confirmationMessages.deleteCompany.message", { companyName: name }) + dependentProfiles,
                title: t("confirmationMessages.deleteCompany.title")
            }
        }
        else if (store.currentCompanyItem?.type === "Location") {
            return {
                message: t("confirmationMessages.deleteLocation.message", { locationName: name }) + dependentProfiles,
                title: t("confirmationMessages.deleteLocation.title")
            }
        }
        else if (store.currentCompanyItem?.type === "LocationLanguage") {
            return {
                message: t("confirmationMessages.deleteLanguage.message", { languageName: name }) + dependentProfiles,
                title: t("confirmationMessages.deleteLanguage.title")
            }
        }
        else return { message: "", title: "" }
    }, [store.currentCompanyItem?.item.name, store.currentCompanyItem?.item.quantityDependentProfiles, store.currentCompanyItem?.type, t]);

    const dialogChildren = React.useMemo(() => {
        if ((store.currentCompanyItem?.item.quantityDependentProfiles ?? 0) > 0){
            return (
            <MessageBar messageBarType={MessageBarType.warning}>
                {tGlobal("general.dependentProfileCount", {n: store.currentCompanyItem?.item.quantityDependentProfiles})}
            </MessageBar>)
        }
        return undefined;
    }, [store.currentCompanyItem?.item.quantityDependentProfiles, tGlobal]);

    return (
        <Stack styles={{ root: { width: "100%" } }} verticalFill tokens={{ childrenGap: 30 }}>
            <Stack.Item grow={1} styles={{ root: { maxWidth: "40em" } }}>
                {editView}
            </Stack.Item>
            <Stack.Item styles={{ root: { width: "100%" } }}>
                <Stack horizontal>
                    <Stack.Item grow={1}>
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <Stack.Item align="start">
                                <PrimaryButton disabled={noChangesMade} onClick={() => onClickSave()}>{tGlobal("general.save")}</PrimaryButton>
                            </Stack.Item>
                            <Stack.Item align="start">
                                <DefaultButton disabled={noChangesMade} onClick={() => onClickCancel()}>{tGlobal("general.cancel")}</DefaultButton>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <DeleteButton 
                            onClick={() => setShowDeleteConfirmation(true)}
                            disabled={!store.currentCompanyItem}
                        >
                            {tGlobal("general.delete")}</DeleteButton>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
            {showDeleteConfirmation &&
                <ConfirmationDialog
                    confirmButtonDanger
                    message={confirmationTranslation.message}
                    title={confirmationTranslation.title}
                    onClose={(confirmed) => onCloseConfirmationDialog(confirmed)} 
                    children={dialogChildren}
                    />
            }
        </Stack>

    );
};