import { isTypeof } from "@microsoft/applicationinsights-core-js";
import React from "react";
import { useMonitoringStore } from "../state/useMonitoringStore";

export const baseUrl = "/api/";

export interface fetchOptions {
  method?: "GET" | "POST" | "PATCH" | "DELETE" | "PUT",
  params?: Map<string, string>,
  body?: string | FormData
  returnType?: "json" | "blob" | "boolean",
  isFile?: boolean
}

export const useFetch = () => {
  const logWarning = useMonitoringStore(store => store.logWarning);

  const fetchApi = React.useCallback(async (url: string, accessToken: string, options?: fetchOptions): Promise<Response> => {
    const urlObj: URL = new URL(`${baseUrl}${url}`, window.location.origin);
    options?.params?.forEach((value, key) => urlObj.searchParams.append(key, value));

    let headers = new Headers();
    // headers.append("Authorization", `Bearer ${accessToken}`); // Does not work, because Static Web App overrides the default authorization header; see https://github.com/Azure/static-web-apps/issues/34 for further details
    headers.append("X-Custom-Authorization", `Bearer ${accessToken}`);
    if (typeof options?.body === "string") {
      headers.append("Content-Type", "application/json");
    }
    if (isTypeof(options?.body, "FormData")) {
      headers.append("Content-Type", "multipart/form-data");
    }
  
    if(options?.isFile) {
      headers.append("Content-Type", "application/octet-stream");
    }

    const result = await fetch(urlObj, {
      method: options?.method,
      body: options?.body,
      headers: headers,
    });
    if (!result.ok){
      const error = {
        url: url,
        accessToken: accessToken, // todo should this be logged?
        options: options ?? {},
        status: result.status,
        response: await result.text()
      }
      logWarning(`fetching api failed, ${JSON.stringify(error)}`)
    }
    return result; 
  }, [logWarning]);

  const fetchBoolean = React.useCallback(async (url: string, accessToken: string, options?: fetchOptions): Promise<boolean> => {
    const result = await fetchApi(url, accessToken, options);
    return result.ok;
  }, [fetchApi]);
  
  const fetchJson = React.useCallback(async (url: string, accessToken: string, options?: fetchOptions): Promise<any|undefined> => {
    const result = await fetchApi(url, accessToken, options);
    if (!result.ok){
      return undefined;
    }
    return result.json();
  }, [fetchApi])
  
  const fetchBlob = React.useCallback(async (url: string, accessToken: string, options?: fetchOptions): Promise<any|undefined> => {
    const result = await fetchApi(url, accessToken, options);
    if (!result.ok){
      return undefined;
    }
    return result.blob();
  }, [fetchApi]);

  return React.useMemo(() => (
    {
      fetchBlob,
      fetchBoolean,
      fetchJson
    }
  ), [fetchBlob, fetchBoolean, fetchJson])
};

