import * as React from "react";
import { SignInButton } from "../SignInButton/SignInButton";
import UserControlPanel from "../UserControlPanel/UserControlPanel";
// import claas_header from "./../../../../assets/Claas_Logo.png";
import useStyles from "./TitleBar.styles";
export interface TitleBarProps {
    isAuthenticated: boolean;
}

export default function TitleBar(props: TitleBarProps) {
    const styles = useStyles();
    return (
        <div className={styles.titleBar}>
            <img src={require(`./../../../../assets/claas_header.jpg`)} alt="claas_logo"/>
            <div className={styles.rightAlignWrapper}>
                {props.isAuthenticated ? <UserControlPanel /> : <SignInButton /> }
            </div>
        </div>
    );
}
