import React from "react";
import { useFetch } from "../../api/fetch";
import useGenericDeleteApiCall from "../../api/useGenericDeleteApiCall";
import useTranslateLanguage from "../../hooks/useTranslateLanguage";
import { Location } from "../../model/Location";
import { NullableOptional } from "../../model/nullable";
import { generatePatchDoc } from "../../utils/buildPatchDoc";
import { useTokenApi } from "./tokenApi";


export const useUpdateLocation = () => {
    const { getToken } = useTokenApi();
    const { translateLanguage } = useTranslateLanguage();
    const { fetchJson } = useFetch();
    
    return React.useCallback(async (id: number, data: Partial<NullableOptional<Location>>): Promise<Location | undefined> => {
        const token = await getToken();
        const dto = {
            name: data.name,
        }

        const body = generatePatchDoc(dto);
        let result = await fetchJson(`location/${id}`, token, { method: "PATCH", body: JSON.stringify(body) }) as Location | undefined;
        if (result && result.languages) {
            result.languages = result.languages.map(la => translateLanguage(la));
        }
        return result
    }, [fetchJson, getToken, translateLanguage]);
}
export const useDeleteLocation = () => {
    const { getToken } = useTokenApi();
    const genericDeleteApiCall = useGenericDeleteApiCall();
    
    return React.useCallback(async (id: number): Promise<boolean> => {
        const token = await getToken();
        return await genericDeleteApiCall(`location/${id}`, token);
    }, [genericDeleteApiCall, getToken]);
}
export const useCreateLocation = () => {
    const { getToken } = useTokenApi();
    const { fetchJson } = useFetch();

    return React.useCallback(async (companyId: number, name: string): Promise<Location | undefined> => {
        const token = await getToken();
        const body = {
            companyId: companyId,
            name: name.trim(),
        };
        return await fetchJson(`location`, token, { method: "POST", body: JSON.stringify(body) }) as Location;
    }, [fetchJson, getToken]);
}

export const useGetLocationDependentProfileCount = () => {
    const { getToken } = useTokenApi();
    const { fetchJson } = useFetch();

    return React.useCallback(async (id: number): Promise<number | undefined> => {
        const token = await getToken();
        const result = await fetchJson(`location/countProfiles/${id}`, token) as { count: number } | undefined;
        return result?.count;
    }, [getToken]);
}