import { MessageBar, Text, TextField } from "@fluentui/react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Company } from "../../../../model/Company";
import { nullIfEmpty } from "../../../../utils/undefinedIfEmpty";
import { useAdminApi } from "../../../api/useAdminApi";
import { useAdminStore } from "../../../state/useAdminState";

export interface CompaniesPageEditCompanyViewProps {
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const CompaniesPageEditCompanyView = (props: CompaniesPageEditCompanyViewProps) => {
    const { t } = useTranslation("translation");
    const store = useAdminStore();
    const api = useAdminApi();
    const entityToShow = React.useMemo(() => {
        if (store.currentCompanyItem?.type === "Company"){
            return {...store.currentCompanyItem?.item, ...store.editedCompanyItem?.item}
        }
    }, [store.currentCompanyItem?.item, store.currentCompanyItem?.type, store.editedCompanyItem?.item]);

    React.useEffect(() => {
        const determineProfiles = async () => {
            if (!store.currentCompanyItem?.item || store.currentCompanyItem.item.quantityDependentProfiles !== undefined) return;
            const count = await api.getCompanyDependentProfileCount(store.currentCompanyItem?.item.id ?? -1);
            const company = store.currentCompanyItem.item;
            company.quantityDependentProfiles = count;
            store.updateCompany(company);
        }
        void determineProfiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.currentCompanyItem?.item.id]);

    return (
        <div>
            <Text 
                variant="xLargePlus">{t("companiesPageEditCompanyView.title")}
            </Text>
            { (store.currentCompanyItem?.item.quantityDependentProfiles ?? 0) > 0 &&
                <MessageBar>
                    {t("general.dependentProfileCount", {n: store.currentCompanyItem?.item.quantityDependentProfiles})}
                </MessageBar>
            }
            <TextField 
                styles={{root: {marginTop: "1em"}}} 
                label={t("general.company.name")}
                value={entityToShow?.name ?? ""}
                onChange={(_ev, newValue) => store.patchEditedCompanyItem<Company>({name: nullIfEmpty(newValue)})}
                >
            </TextField>
        </div>
    );
};