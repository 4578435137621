import React from "react";
import { useTranslation } from "react-i18next";
import { LocationLanguage } from "../model/LocationLanguage";

export const useTranslateLanguage = () =>{
    const { t: tLanguages} = useTranslation("translation", { keyPrefix: "languages" });

    const translateLanguage = React.useCallback((language: LocationLanguage) => {
        return {...language, name: tLanguages(language.isoCode?? "en") } as LocationLanguage;
    }, [tLanguages]);

    const translateLanguageCode = React.useCallback((isoCode: string) => {
        return tLanguages(isoCode) as string;
    }, [tLanguages]);

    return React.useMemo(() => ({
        translateLanguage,
        translateLanguageCode
    }), [translateLanguage, translateLanguageCode]);
}

export default useTranslateLanguage;