export const msalConfig = {
    auth: {
      clientId: "<1180da10-ec8e-4e7c-8514-8e6e88b91ad6>",
      authority: "https://login.microsoftonline.com/<fbfd35b9-5fbf-4fb4-b148-e155dd92008f>", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: "<8d5df4bc-4c1b-479a-a456-71547b410973>",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["User.Read"]
  };

  export const backendRequest = {
    scopes: ["<cd5cbd93-99db-4bf1-9b65-fc8f93e8ff37>"],
  }
  