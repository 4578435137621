import { Link } from "@fluentui/react/lib/Link";
import { css } from "@fluentui/utilities/lib/css";
import * as React from "react";
import { useMemo } from "react";
import useStyles from "./FileNameDisplay.styles";
import { baseUrl } from "../../../api/fetch";
import { Icon, Spinner, SpinnerSize } from '@fluentui/react';
import { useAdminApi } from "../../api/useAdminApi";

export interface IFileNameDisplayProps {
    fileName: string;
    fileId?: number;
    /**     * Determines if the file icon should be hidden     */    hideIcon?: boolean;
}
export const TemplateFileDisplay = ((props: IFileNameDisplayProps): JSX.Element => {
    const styles = useStyles();

    const adminApi = useAdminApi();
    const [isDownloading, setIsDownloading] = React.useState<boolean>(false);

    const downloadFile = React.useCallback(async (id: number | undefined) => {
        if (id == null) {
            return;
        }
        if(isDownloading) {
            return;
        }
        setIsDownloading(true);
        try{
            var downloadedFile = await adminApi.downloadTemplate(id);
            if (!downloadedFile) {
                return;
            }
            const a = document.createElement("a");
            const url = window.URL.createObjectURL(downloadedFile);
            a.href = url;
            a.setAttribute("download", props.fileName);
            // document.body.appendChild(a);
            a.click();
        }
        catch {

        }
        setIsDownloading(false);    
    }, [adminApi, isDownloading, props.fileName])

    return (
        <div className={css(styles.container)}>
            {!props.hideIcon &&
                <div className={styles.fileIcon}>
                    <Icon iconName="TextDocument"></Icon>
                    {/* <FileTypeIcon type={IconType.image} path={props.fileName} size={ImageSize.normal} />                 */}
                </div>}
            <div className={styles.fileName}>
                {props.fileId == null ? props.fileName :
                    <Link disabled={isDownloading} href="#" onClick={(e) => { e.stopPropagation(); downloadFile(props.fileId); return false; }}>{props.fileName}</Link>}
                {isDownloading && <Spinner className={styles.spinner} size={SpinnerSize.medium} ></Spinner>}
            </div>
        </div>);
})