import { SelectionMode } from "@fluentui/utilities";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToasts } from "../../../hooks/useToasts";
import { CategoryType } from "../../../model/CategoryType";
import { RoleType } from "../../../model/RoleType";
import { Template } from "../../../model/Template";
import { filterList } from "../../../utils/filterList";
import { useAdminApi } from "../../api/useAdminApi";
import { useSessionStore } from "../../state/useSessionStore";
import { useTemplateStore } from "../../state/useTemplateStore";
import { GenericList, IFlexibleColumn } from "../GenericList/GenericList";
import { TemplateEditModal } from "../TemplateEditModal/TemplateEditModal";
import { TemplateNewModal } from "../TemplateNewModal/TemplateNewModal";
import useStyles from "./TemplateList.styles";

export interface TemplateListProps {

}

export const TemplateList = (props: TemplateListProps): JSX.Element => {
    const toasts = useToasts();
    const { loadingState, templates, setLoadingState, setTemplates } = useTemplateStore();
    const { getTemplates, deleteTemplate } = useAdminApi();
    const { t } = useTranslation("translation", { keyPrefix: "templatePage" });
    const { t: tr } = useTranslation("translation", { keyPrefix: "templateCategory" });
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const [isCreateTemplateModalVisible, setIsNewTemplateModalVisible] = useState<boolean>(false);
    const [isEditTemplateModalVisible, setIsEditTemplateModalVisible] = useState<boolean>(false);
    const styles = useStyles();
    const { hasRole } = useSessionStore();
    const [selectedTemplates, setSelectedTemplates] = useState<Template[]>([])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    function getCategoryNameByCategoryType(id: CategoryType | undefined) {
        let roleName = "";
        switch (id) {
            case CategoryType.Letter:
                roleName = tr("letter");
                break;
            case CategoryType.Fax:
                roleName = tr("fax");
                break;
            case CategoryType.Other:
                roleName = tr("other");
                break;
            default:
        }
        return roleName;
    }
    // eslint-enable-next-line react-hooks/exhaustive-deps

    const loadTemplates = useCallback(async () => {
        setLoadingState("Loading")
        const templates = await getTemplates();
        if (templates) {
            setTemplates(templates);
            setLoadingState("Loaded")

        } else {
            setLoadingState("Failure")
        }

    }, [getTemplates, setLoadingState, setTemplates]);

    useEffect(() => {
        if (loadingState === "NotLoaded") {
            loadTemplates();
        }
    }, [loadTemplates, loadingState]);



    const columns: IFlexibleColumn[] = useMemo(() => [
        {
            key: 'id',
            name: t("columns.id"),
            fieldName: 'id',
            minWidth: 16,
            maxWidth: 100,
        },
        {
            key: 'name',
            name: t("columns.name"),
            fieldName: 'name',
            minWidth: 100,
            maxWidth: 300,
        },
        {
            key: 'category',
            name: t("columns.category"),
            fieldName: 'category',
            minWidth: 16,
            maxWidth: 100,
            onRender: (item: Template) => {
                return (<span>{getCategoryNameByCategoryType(item.category)}</span>);
            }
        },
        {
            key: 'sharePointId',
            name: t("columns.sharePointId"),
            fieldName: 'sharePointId',
            minWidth: 16,
            maxWidth: 100,
        }
    ], [getCategoryNameByCategoryType, t]);

    const displayedTemplates = useMemo(() => {
        const filteredTemplates = searchTerm ? filterList(searchTerm, templates ?? [], columns.map(c => c.fieldName)) : templates;
        return filteredTemplates ?? [];
    }, [columns, searchTerm, templates]);

    const onChangeSearch = useCallback((value: string | undefined) => {
        setSearchTerm(value);
    }, []);

    const onDismissCreate = useCallback(() => {
        setIsNewTemplateModalVisible(false);
        loadTemplates();
    }, [loadTemplates]);

    const onEditCancel = useCallback(() => {
        setIsEditTemplateModalVisible(false);
    }, []);

    const onEditSave = useCallback(() => {
        setIsEditTemplateModalVisible(false);
        loadTemplates();
    }, [loadTemplates]);

    const onDelete = useCallback(async (templatesToDelete: Template[]) => {
        for (let template of templatesToDelete) {
            const deletedTemplate = await deleteTemplate(template.id);
            if (deletedTemplate != null) {
                const newArray = templates?.filter(entry => entry.id !== template.id);
                setTemplates(newArray);
                toasts.deleteSuccess();
            } else {
                toasts.deleteFailure();
            }
        }
        loadTemplates();
    }, [deleteTemplate, loadTemplates, setTemplates, templates, toasts]);

    const onSelection = useCallback((templatesSelected: Template[]) => {
        setSelectedTemplates(templatesSelected);
    }, [])

    return (
        <>
        <GenericList<Template>
            onChangeSearch={onChangeSearch}
            styles={styles}
            columns={columns}
            displayedItems={displayedTemplates}
            selectionMode={SelectionMode.multiple}
            onSelectionChanged={onSelection}
            deleteButton={{onDelete: onDelete, visible: hasRole(RoleType.GlobalAdmin)}}
            newButton={{onClick: () => setIsNewTemplateModalVisible(true), visible: hasRole(RoleType.GlobalAdmin)}}
            editButton={{onClick: () => setIsEditTemplateModalVisible(true), visible: hasRole(RoleType.GlobalAdmin)}}
        />
        <TemplateNewModal isOpen={isCreateTemplateModalVisible} onDismiss={onDismissCreate}></TemplateNewModal>
        <TemplateEditModal isOpen={isEditTemplateModalVisible} onDismissCancel={onEditCancel} onDismissSave={onEditSave} selectedTemplate={selectedTemplates[0]}></TemplateEditModal>
        </>
    );
}