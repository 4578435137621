import React from "react";
import useTranslateLanguage from "../hooks/useTranslateLanguage";
import { LocationLanguage } from "../model/LocationLanguage";
import { useFetch } from "./fetch";

export const useGetLanguages = () => {
    const { translateLanguage } = useTranslateLanguage();
    const { fetchJson } = useFetch();

    return React.useCallback(async (locationId?: number): Promise<LocationLanguage[] | undefined> => {
        const token = await OfficeRuntime.auth.getAccessToken();
        const params = locationId ? new Map([["locationId", locationId.toString()]]) : undefined;
        const result = (await fetchJson("languages", token, { params: params })) as LocationLanguage[];
        return result.map(x => translateLanguage(x));
    }, [fetchJson, translateLanguage]);
}
export const useGetLanguage = () => {
    const { translateLanguage } = useTranslateLanguage();
    const { fetchJson } = useFetch();

    return React.useCallback(async (id: number): Promise<LocationLanguage | undefined> => {
        const token = await OfficeRuntime.auth.getAccessToken();
        const result = await fetchJson(`language/${id}`, token) as LocationLanguage|undefined;
        return result ? translateLanguage(result) : undefined;
    }, [fetchJson, translateLanguage]);
}
