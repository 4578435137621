import { Text } from "@fluentui/react";
import { BaseButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { ComboBox, IComboBoxOption } from "@fluentui/react/lib/ComboBox";
import { DetailsList, IColumn, ISelection, Selection, SelectionMode } from "@fluentui/react/lib/DetailsList";
import { Modal } from "@fluentui/react/lib/Modal";
import { TextField } from "@fluentui/react/lib/TextField";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AdUserLookupField } from "../../../components/AdUserLookupField/AdUserLookupField";
import { DeleteButton } from "../../../components/DeleteButton/DeleteButton";
import { ILookupValue } from "../../../components/LookupField/LookupField";
import { AdUser } from "../../../model/AdUser";
import { Company } from "../../../model/Company";
import { RoleType } from "../../../model/RoleType";
import { UpsertUserDto } from "../../../model/UpsertUserDto";
import { User } from "../../../model/User";
import { UserRole } from "../../../model/UserRole";
import { useAdminApi } from "../../api/useAdminApi";
import { useSessionStore } from "../../state/useSessionStore";
import useStyles from "./UserEditModal.styles";

export interface UserEditModalProps {
    isOpen: boolean;
    onDismiss: () => void;
    onSave: (user: User | undefined) => void;
    onDelete: (user: User | undefined) => void;
    selectedUser: User | undefined;
}

export const UserEditModal = (props: UserEditModalProps): JSX.Element => {
    const { t } = useTranslation("translation", { keyPrefix: "userEditModal" });
    const { t: tr } = useTranslation("translation", { keyPrefix: "roles" });
    const { t: tu } = useTranslation("translation", { keyPrefix: "usersPage" });
    const { t: tGeneral } = useTranslation("translation");

    const [currentName, setCurrentName] = useState<string | undefined>(undefined);
    const [currentOId, setCurrentOId] = useState<string | undefined>(undefined);
    const [selectedRole, setSelectedRole] = useState<RoleType | undefined>(undefined);
    const [companies, setCompanies] = useState<Company[]>([]);
    const [selectedCompanies, setSelectedCompanies] = useState<Company[]>([]);
    const [selectedAdUser, setSelectedAdUser] = React.useState<AdUser|undefined>(undefined);
    const adminApi = useAdminApi();
    const styles = useStyles();
    const { currentUser, hasRole } = useSessionStore();

    const areControlsDisabled = useMemo(() => {
        //cannot edit yourself
        if (currentUser?.id === props.selectedUser?.id) {
            return true;
        }
        if (hasRole(RoleType.User)) {
            return true;
        }
        if (hasRole(RoleType.CompanyAdmin) && props.selectedUser?.userRoles.find(r => r.roleId === RoleType.CompanyAdmin || r.roleId === RoleType.GlobalAdmin)) {
            return true;
        }
        return false;
    }, [currentUser?.id, hasRole, props.selectedUser]);


    const onSelectionChange = useCallback((selection?: ISelection) => {
        const selectedItems = selection?.getSelection() as Company[];
        setSelectedCompanies(selectedItems);
    }, []);

    // Handle selection object
    const selection: ISelection = useMemo(() => {
        return new Selection({
            onSelectionChanged: () => onSelectionChange(selection),
            getKey: (item) => (item as Company).id,
        });
    }, [onSelectionChange]);

    const columns: IColumn[] = React.useMemo(() => {
        return [
            {
                key: 'name',
                name: tu("columns.name"),
                fieldName: 'name',
                minWidth: 16,
                maxWidth: 100,
            },
        ];
    }, [tu]);

    const roleOptions: IComboBoxOption[] = useMemo(()=> {
        if(hasRole(RoleType.GlobalAdmin)){
            return [
                {
                    key: RoleType.User,
                    text: tr("user"),
                },
                {
                    key: RoleType.GlobalAdmin,
                    text: tr("globalAdmin"),
                },
                {
                    key: RoleType.CompanyAdmin,
                    text: tr("companyAdmin"),
                },
            ]
        }
        if(hasRole(RoleType.CompanyAdmin)){
            return [
                {
                    key: RoleType.User,
                    text: tr("user"),
                }
            ]
        }
        return [];
    },[hasRole, tr]); 
    
    const getCompanies = useCallback(async () => {
        const companies = await adminApi.getCompanies();

        if (companies) {
            setCompanies(companies);
            props.selectedUser?.userRoles.filter(u => u.companyId !== undefined).forEach(r => selection.setKeySelected(r.companyId?.toString() ?? "", true, true))
        } else {
            //TODO log error
        }
    }, [adminApi, props.selectedUser?.userRoles, selection]);

    // init fields
    useEffect(() => {
        if (!currentName && !currentOId && !selectedRole) {
            const user = props.selectedUser;
            setCurrentName(user?.displayName);
            setCurrentOId(user?.oId);
            setSelectedAdUser(
                user ? 
                {
                    oId: user.oId,
                    displayName: user.displayName
                } 
                : undefined)
            setSelectedRole(user?.userRoles.at(0)?.roleId ?? undefined)
        }
        getCompanies();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getCompanies, props.selectedUser]);

    const renderCompanyList = useMemo(() => {

        if (selectedRole === RoleType.CompanyAdmin) {
            return (
                <div>
                    <h3>{t("companies")}</h3>
                    <DetailsList
                        items={companies}
                        selectionMode={areControlsDisabled ? SelectionMode.none : SelectionMode.multiple}
                        selection={areControlsDisabled ? undefined : selection}
                        columns={columns}
                        compact={true}
                    />
                </div>);
        } else {
            return (<></>);
        }
    }, [selectedRole, t, companies, areControlsDisabled, selection, columns]);

    const onDismiss = useCallback(() => {
        props.onDismiss();
        setCurrentName(undefined);
        setCurrentOId(undefined);
        setSelectedRole(undefined);
        setSelectedAdUser(undefined);
        props.selectedUser?.userRoles.filter(u => u.companyId !== undefined).forEach(r => selection.setKeySelected(r.companyId?.toString() ?? "", true, true))
    }, [props, selection]);

    const editUser = React.useCallback(async () => {
        if (!props.selectedUser) return;

        let updatedUser: UpsertUserDto = {
            id: props.selectedUser.id,
            displayName: props.selectedUser.displayName,
            oId: props.selectedUser.oId,
            roles: props.selectedUser.userRoles.map(x => 
                x.companyId ? ({roleId: x.roleId, companyId: x.companyId}) : ({roleId: x.roleId}))
        };

        switch (selectedRole) {
            case RoleType.GlobalAdmin:
            case RoleType.User:
                updatedUser.roles = [{
                    roleId: selectedRole,
                }];
                break;
            case RoleType.CompanyAdmin:
                updatedUser.roles = selectedCompanies.map(c => ({ roleId: selectedRole, companyId: c.id } as UserRole));
                break;
            default:
        }
        const result = await adminApi.setUser(updatedUser);

        props.onSave(result);
        onDismiss();
    }, [adminApi, onDismiss, props, selectedCompanies, selectedRole]);

    const createUser = React.useCallback(async () => {
        const updatedUser: UpsertUserDto = {
            oId: currentOId ?? "",
            displayName: currentName ?? "",
            roles: []
        }

        if (selectedRole !== undefined) {
            if (selectedRole === RoleType.CompanyAdmin) {
                const roles = selectedCompanies.map(c => ({ roleId: selectedRole, companyId: c.id }));
                updatedUser.roles.push(...roles);
            }
            else {
                updatedUser.roles.push({
                    roleId: selectedRole,
                })
            }
        }

        const result = await adminApi.setUser(updatedUser);

        props.onSave(result);
        onDismiss();
    }, [adminApi, currentName, currentOId, onDismiss, props, selectedCompanies, selectedRole]);

    const onSave = useCallback(async () => {
        if (props.selectedUser) {
            await editUser();
        }
        else {
            await createUser();
        }
    }, [createUser, editUser, props.selectedUser]);

    const onChangeAdUser = React.useCallback((item: ILookupValue | undefined) => {
        if (item?.object){
            setCurrentName((item.object as AdUser).displayName);
            setCurrentOId((item.object as AdUser).oId);
            setSelectedAdUser({
                displayName: (item.object as AdUser).displayName,
                oId: (item.object as AdUser).oId,
            })
        }
        else {
            setCurrentName(undefined);
            setCurrentOId(undefined);
            setSelectedAdUser(undefined);
        }
        
    }, []);

    const isSaveButtonDisabled = React.useMemo(() => {
        return areControlsDisabled || (currentName === undefined && currentOId === undefined);
    }, [areControlsDisabled, currentName, currentOId]);

    return (
        <div>
            <Modal
                isBlocking={true}
                isOpen={props.isOpen}
                onDismiss={onDismiss}
            >
                <div className={styles.flexWrapper}>
                    <div>
                        <Text variant="xLarge">{props.selectedUser ? t("editUserTitle") : t("createUserTitle")}</Text>
                    </div>
                    <div>
                        <AdUserLookupField 
                            selectedUser={selectedAdUser}
                            onChange={onChangeAdUser}
                            disabled={props.selectedUser !== undefined}
                            />
                        <TextField label={t("name")} value={currentName} onChange={(event, newValue) => setCurrentName(newValue)} disabled={true}></TextField>
                        <TextField label={t("oId")} value={currentOId} onChange={(event, newValue) => setCurrentOId(newValue)} disabled={true}></TextField>
                        <ComboBox
                            label={t("role")}
                            options={roleOptions}
                            selectedKey={selectedRole}
                            onChange={(event, option) => setSelectedRole(option?.key as RoleType ?? undefined)}
                            disabled={areControlsDisabled}
                        />
                        {renderCompanyList}
                        <div className={styles.buttonRow}>
                            <DeleteButton className={styles.deleteButton} disabled={areControlsDisabled} onClick={() => props.onDelete(props.selectedUser)}>{tGeneral("general.delete")}</DeleteButton>
                            <BaseButton className={styles.abortButton} onClick={onDismiss}>{tGeneral("general.cancel")}</BaseButton>
                            <PrimaryButton className={styles.saveButton} onClick={onSave} disabled={isSaveButtonDisabled}>{tu("saveButton")}</PrimaryButton>
                        </div>
                    </div>
                </div>
            </Modal >
        </div >
    )
}


