import React from "react";
import { useFetch } from "../../api/fetch";
import useGenericDeleteApiCall from "../../api/useGenericDeleteApiCall";
import useTranslateLanguage from "../../hooks/useTranslateLanguage";
import { Company } from "../../model/Company";
import { NullableOptional } from "../../model/nullable";
import { generatePatchDoc } from "../../utils/buildPatchDoc";
import { useTokenApi } from "./tokenApi";

export const useGetCompanies = () => {
    const { getToken } = useTokenApi();
    const { translateLanguage } = useTranslateLanguage()
    const { fetchJson } = useFetch();
    
    return React.useCallback(async (filter?: string): Promise<Company[] | undefined> => {
        const token = await getToken();
        const params = filter ? new Map([["name", filter]]) : undefined;
        const result = (await fetchJson("companies", token, { params: params })) as Company[];
        if (result) result.forEach(x => x.locations?.forEach(y => y.languages?.forEach((z, i) => {
            if (z && y.languages) y.languages[i] = translateLanguage(z);
        })))
        return result;
    }, [fetchJson, getToken, translateLanguage]);
}

export const useUpdateCompany = () => {
    const { getToken } = useTokenApi();
    const { translateLanguage } = useTranslateLanguage();
    const { fetchJson } = useFetch();

    return React.useCallback(async (id: number, data: Partial<NullableOptional<Company>>): Promise<Company | undefined> => {
        const token = await getToken();
        const dto = {
            name: data.name,
        }

        const body = generatePatchDoc(dto);
        let result = await fetchJson(`company/${id}`, token, { method: "PATCH", body: JSON.stringify(body) }) as Company | undefined;
        if (result) {
            result.locations?.forEach(lo => {
                lo.languages?.forEach((la, i) => {
                    if (la && lo.languages) lo.languages[i] = translateLanguage(la);
                })
            });
        }
        return result;
    }, [fetchJson, getToken, translateLanguage]);
}

export const useDeleteCompany = () => {
    const { getToken } = useTokenApi();
    const genericDeleteApiCall = useGenericDeleteApiCall();
    
    return React.useCallback(async (id: number): Promise<boolean> => {
        const token = await getToken();
        return await genericDeleteApiCall(`company/${id}`, token);
    }, [genericDeleteApiCall, getToken]);
}

export const useCreateCompany = () => {
    const { getToken } = useTokenApi();
    const { fetchJson } = useFetch();

    return React.useCallback(async (name: string): Promise<Company | undefined> => {
        const token = await getToken();
        const body = {
            name: name.trim()
        };
        return await fetchJson(`company`, token, { method: "POST", body: JSON.stringify(body) }) as Company;
    }, [fetchJson, getToken]);
}

export const useGetCompanyDependentProfileCount = () => {
    const { getToken } = useTokenApi();
    const { fetchJson } = useFetch();

    return React.useCallback(async (id: number): Promise<number | undefined> => {
        const token = await getToken();
        const result = await fetchJson(`company/countProfiles/${id}`, token) as {count: number} | undefined;
        return result?.count;
    }, [fetchJson, getToken]);
}