import React from "react";
import { useFetch } from "../../api/fetch";
import useGenericDeleteApiCall from "../../api/useGenericDeleteApiCall";
import { AdUser } from "../../model/AdUser";
import { NullableOptional } from "../../model/nullable";
import { UpsertUserDto } from "../../model/UpsertUserDto";
import { User } from "../../model/User";
import { useMonitoringStore } from "../../state/useMonitoringStore";
import { generatePatchDoc } from "../../utils/buildPatchDoc";
import { useTokenApi } from "./tokenApi";


export const useGetUsers = () => {
    const { getToken } = useTokenApi();
    const { fetchJson } = useFetch();

    return React.useCallback(async (): Promise<User[] | undefined> => {
        const token = await getToken();
        return await fetchJson("user", token) as User[];
    }, [fetchJson, getToken]);
}

export const useUpdateUser = () => {
    const { getToken } = useTokenApi();
    const { logInfo } = useMonitoringStore();
    const { fetchJson } = useFetch();

    return React.useCallback(async (id: number, data: Partial<NullableOptional<User>>): Promise<User | undefined> => {
        const token = await getToken();
        // only patch updated properties
        const dto = {
            oId: data.oId,
            displayName: data.displayName,
        }

        const body = generatePatchDoc(dto);
        logInfo("Body for patch request has been generated", { body });
        return await fetchJson(`user/${id}`, token, { method: "PATCH", body: JSON.stringify(body) }) as User;
    }, [fetchJson, getToken, logInfo]);
}

export const useCreateUser = () => {
    const { getToken } = useTokenApi();
    const { fetchJson } = useFetch();

    return React.useCallback(async (data: User): Promise<User | undefined> => {
        const token = await getToken();

        return await fetchJson(`user`, token, { method: "POST", body: JSON.stringify(data) }) as User;
    }, [fetchJson, getToken]);
}

export const useSetUser = () => {
    const { getToken } = useTokenApi();
    const { fetchJson } = useFetch();

    return React.useCallback(async (data: UpsertUserDto): Promise<User | undefined> => {
        const token = await getToken();
        return await fetchJson(`user`, token, { method: "PUT", body: JSON.stringify(data) }) as User;
    }, [fetchJson, getToken]);
}

export const useDeleteUser = () => {
    const { getToken } = useTokenApi();
    const genericDeleteApiCall = useGenericDeleteApiCall();
    
    return React.useCallback(async ( id: number): Promise<boolean> => {
        const token = await getToken();
        return await genericDeleteApiCall(`user/${id}`, token);
    }, [genericDeleteApiCall, getToken]);
} 

export const useGetAdUsers = () => {
    const { getToken } = useTokenApi();
    const { fetchJson } = useFetch();

    return React.useCallback(async (filter: string): Promise<AdUser[] | undefined> => {
        const token = await getToken();

        return await fetchJson(`adUsers/name/${filter}`, token, { method: "GET" }) as AdUser[];
    }, [fetchJson, getToken]);
}