import { makeStyles } from "@fluentui/react";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let style = makeStyles((theme) => ({
        baseStyle: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '10px',
            padding: '20px',
            borderWidth: 2,
            borderRadius: 2,
            borderColor: '#eeeeee',
            borderStyle: 'dashed',
            backgroundColor: '#fafafa',
            color: '#bdbdbd',
            outline: 'none',
            transition: 'border .24s ease-in-out'
        },
        focusedStyle: {
            borderColor: '#2196f3'
        },
        
        acceptStyle: {
            borderColor: '#00e676'
        },
        
        rejectStyle: {
            borderColor: '#ff1744'
        },

        errBarStyle: {
            marginTop: "5px"
        }
    }));
    return style();
};