import { useIsAuthenticated } from "@azure/msal-react";
import React from "react";
import { useAdminApi } from "../../api/useAdminApi";
import { useSessionStore } from "../../state/useSessionStore";
import { BaseLayout } from "../Layout/BaseLayout";
import NavMenu from "../NavMenu/NavMenu";
import TitleBar from "../TitleBar/TitleBar";


export const Layout = (props: React.PropsWithChildren<{}>): JSX.Element => {
    const isAuthenticated = useIsAuthenticated();
    const sessionStore = useSessionStore();
    const api = useAdminApi();
    React.useEffect(() => {
        async function retrieveUserInfo() {
            const response = await api.getWhoAmI();
            if(response){
                sessionStore.setCurrentUser(response)
                sessionStore.setLoadingState("Loaded");
            }else{
                sessionStore.setLoadingState("Failure")
            }
        }
        if (isAuthenticated) {
            sessionStore.setLoadingState("Loading");
            retrieveUserInfo();
        }
    }, [isAuthenticated]);

    return (
        <BaseLayout
            titleBar={<TitleBar
                isAuthenticated={isAuthenticated}
            />}
            navMenu={isAuthenticated ? <NavMenu /> : <></>}
            content={
                <>
                    {isAuthenticated ? props.children : undefined}
                </>
            }
        />
    );
}