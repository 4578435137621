import React from "react";
import { TemplateList } from "../../components/TemplateList/TemplateList";

export const TemplatePage = (): JSX.Element => {

    return (
    <>
        <TemplateList />
    </>
    );
}