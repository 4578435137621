import { makeStyles } from "@fluentui/react";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let style = makeStyles((theme) => ({
        navMenu: {
            backgroundColor: theme.palette.white,
            display: "flex",
            flexDirection: "column",
            height: "87vh",
            
            a: {
                textDecoration: "none",
                fontSize: "20px"
            }
        },

        navItem: {
            padding: "12.5px 40px 12.5px 37px",
            textDecoration: "none",
            color: theme.palette.black,
            transition: "padding 0.3s",


        },
        activeNavItem: {
            padding: "12.5px 40px 12.5px 12px",
            color: theme.palette.greenLight,
        },
        displayText: {

        }
    }));
    return style();
};