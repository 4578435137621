import { INavLink, INavLinkGroup, IRenderFunction, Nav, Text } from "@fluentui/react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { usePrompt } from "../../../hooks/useBlocker";
import { Company } from "../../../model/Company";
import { CurrentCompanyItem } from "../../../model/ICurrentCompanyItem";
import { Location } from "../../../model/Location";
import { LocationLanguage } from "../../../model/LocationLanguage";
import useLoadLocationLanguage from "../../hooks/useLoadLocationLanguage";
import useSetCurrentCompanyItem from "../../hooks/useSetCurrentCompanyItem";
import { useAdminStore } from "../../state/useAdminState";
import { AddLanguageButton } from "../AddLanguageButton/AddLanguageButton";
import { AddLocationButton } from "../AddLocationButton/AddLocationButton";
// import useStyles from "./NavTreeView.style";

interface ExtendedNavLink extends INavLink {
    obj: CurrentCompanyItem,
    type: "Company" | "Location" | "LocationLanguage",
    links: ExtendedNavLink[],
    parent?: ExtendedNavLink,
}

interface ReducedNavLink {
    key?: string,
    isExpanded?: boolean,
}

export interface CompanyTreeViewProps {
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const CompanyTreeView = (props: CompanyTreeViewProps) => {
    const { t } = useTranslation("translation");
    const setCurrentCompanyItem = useSetCurrentCompanyItem();
    // const styles = useStyles();
    const store = useAdminStore();
    const loadLanguage = useLoadLocationLanguage();
    const [items, setItems] = React.useState<ExtendedNavLink[] | undefined>(undefined);

    const getLanguageNavLink = React.useCallback((la: LocationLanguage, parent: ExtendedNavLink, previousItems?: ReducedNavLink[]) => {
        const result = {
            key: `LocationLanguage-${la.id}`,
            obj: la,
            type: "LocationLanguage",
            name: la.name ?? "",
            url: "",
            parent: parent
        } as ExtendedNavLink
        const previousItem = previousItems?.find(i => i.key === result.key);
        if (previousItem) {
            result.isExpanded = previousItem.isExpanded;
        }
        return result;
    }, []);

    const getLocationNavLink = React.useCallback((lo: Location, parent: ExtendedNavLink, previousItems?: ReducedNavLink[]) => {
        const result: ExtendedNavLink =
        {
            key: `Location-${lo.id}`,
            obj: lo,
            type: "Location",
            name: lo.name ?? "",
            url: "",
            links: [],
            parent: parent
        }
        const previousItem = previousItems?.find(i => i.key === result.key);
        if (previousItem) {
            result.isExpanded = previousItem.isExpanded;
        }
        result.links = lo.languages?.map(la => getLanguageNavLink(la, result, previousItems)) ?? [];
        return result;
    }, [getLanguageNavLink]);

    const getCompanyNavLink = React.useCallback((c: Company, previousItems?: ReducedNavLink[]) => {
        const result: ExtendedNavLink = {
            key: `Company-${c.id}`,
            obj: c,
            type: "Company",
            name: c.name ?? "",
            url: "",
            links: []
        }
        const previousItem = previousItems?.find(i => i.key === result.key);
        if (previousItem) {
            result.isExpanded = previousItem.isExpanded;
        }
        result.links = c.locations?.map(lo => getLocationNavLink(lo, result, previousItems)) ?? [];
        return result;
    }, [getLocationNavLink]);

    // rerender list elements based on companies
    React.useEffect(() => {
        // if (items) return;
        const flatten = (item: ExtendedNavLink): ReducedNavLink[] => {
            const rest = (item.links?.map(l => flatten(l)) ?? []).flat()
            return [{ key: item.key, isExpanded: item.isExpanded }, ...rest];
        }
        const previousItems = items?.flatMap(i => flatten(i))

        const companies = store.companies?.map(c => getCompanyNavLink(c, previousItems));
        setItems(companies);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.companies]);

    const changesMade: boolean = store.editedCompanyItem?.item !== undefined && Object.keys(store.editedCompanyItem.item).filter(k => k !== "id").length > 0;
    // prompts confirmation window when changing url and having unsaved changes
    usePrompt(t("popups.dataLoss"), changesMade);

    const selectedKey = React.useMemo(() => {
        return `${store.currentCompanyItem?.type}-${store.currentCompanyItem?.item.id}`
    }, [store.currentCompanyItem?.item.id, store.currentCompanyItem?.type]);

    const onLinkClick = React.useCallback(async (ev: React.MouseEvent<HTMLElement, MouseEvent> | undefined, item: ExtendedNavLink | undefined) => {
        // prevent navlink from opening url
        ev?.preventDefault();
        // do nothing when navigating to current item
        if (item?.key === selectedKey) return;
        // prompts confirmation window when changing item and having unsaved changes
        if (changesMade && !window.confirm(t("popups.dataLoss"))) return;
        
        if (!item?.obj) return;

        setCurrentCompanyItem({ type: item.type, item: item.obj });
        if (item.type !== "LocationLanguage") return;

        // load language if it hasnt been loaded already
        loadLanguage(item.obj.id);
    }, [changesMade, loadLanguage, selectedKey, setCurrentCompanyItem, t]);

    const onRenderLink = React.useCallback((item: ExtendedNavLink | undefined, defaultRender?: IRenderFunction<INavLink>) => {
        return (
            <Text>
                {item?.name}
                {" "}
                {item?.type === "Location" &&
                    <AddLanguageButton location={item.obj} />
                }
                {item?.type === "Company" &&
                    <AddLocationButton company={item.obj} />
                }
            </Text>
        )
    }, []);


    // expand parent of recently added element
    React.useEffect(() => {
        const item = store.addedCompanyItem;
        if (!item) return;
        store.setAddedCompanyItem(undefined);
        const newItems = [...items ?? []];
        if (item.type === "Location") {
            const company = newItems.find(c => c.obj.id === (item.item as Location).companyId);
            if (company) company.isExpanded = true;
            setItems(newItems);
        }

        else if (item.type === "LocationLanguage") {
            newItems.forEach((c) => {
                const location = c.links.find(lo => lo.obj.id === (item.item as LocationLanguage).locationId)
                if (location) location.isExpanded = true;
            })
            setItems(newItems);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.addedCompanyItem, store.setAddedCompanyItem]);

    const groups: INavLinkGroup[] = React.useMemo(() => {
        return [
            {
                links: items ?? [],
            }
        ]
    }, [items]);

    return (
        <div>
            <Nav
                groups={groups}
                onLinkClick={(ev, item) => onLinkClick(ev, item as ExtendedNavLink)}
                onRenderLink={(item, defaultRender) => onRenderLink(item as ExtendedNavLink, defaultRender)}
                selectedKey={selectedKey}
            />
        </div>

    );
};