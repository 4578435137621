import { DefaultButton, Dialog, DialogFooter, DialogType, IDialogContentProps, PrimaryButton, TextField } from "@fluentui/react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { undefinedIfEmpty } from "../../../utils/undefinedIfEmpty";
import useStyles from "./NewEntitiyNameDialog.styles";

export interface INewEntityNameDialogProps {
    title?: string,
    message?: string,
    onClose: (confirmed: boolean, name?: string) => void;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function NewEntityNameDialog(props: INewEntityNameDialogProps) {
    const styles = useStyles();
    const { t } = useTranslation("translation", { keyPrefix: "newEntityNameDialog" });
    const { t: tGeneral } = useTranslation("translation", { keyPrefix: "general" });
    const [name, setName] = React.useState<string|undefined>(undefined);
    const saveDisabled = React.useMemo(() => undefinedIfEmpty(name) === undefined , [name]);

    const modalProps = React.useMemo(() => ({
        isBlocking: false,
    }), []);

    const dialogContentProps = React.useMemo(() => ({
        type: DialogType.normal,
        title: props.title,
        closeButtonAriaLabel: 'Close',
        subText: props.message,
    }) as IDialogContentProps, [props.message, props.title]);

    return (
        <Dialog
            hidden={false}
            onDismiss={() => props.onClose(false)}
            dialogContentProps={dialogContentProps}
            modalProps={modalProps}
        >
            <TextField label={t("name")} value={name??""} onChange={(_event, newValue) => setName(undefinedIfEmpty(newValue))}></TextField>
            <DialogFooter>
                <PrimaryButton disabled={saveDisabled} onClick={() => props.onClose(true, name)} text={tGeneral("confirm")} />
                <DefaultButton onClick={() => props.onClose(false)} text={tGeneral("cancel")} />
            </DialogFooter>
        </Dialog>
    );
}