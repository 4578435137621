import React from "react";
import { useFetch } from "../../api/fetch";
import { User } from "../../model/User";
import { useTokenApi } from "./tokenApi";

export const useWhoAmI = () => {
    const { getToken } = useTokenApi();
    const { fetchJson } = useFetch();
    
    return React.useCallback(async (): Promise<User | undefined> => {
        const t = await getToken();
        return await fetchJson(`whoami`, t);
    }, [fetchJson, getToken])
} 