import * as React from "react";
import { useAdminApi } from "../../admin/api/useAdminApi";
import { AdUser } from "../../model/AdUser";
import LookupField, { ILookupValue } from "../LookupField/LookupField";
// import useStyles from "./AdUserLookupField.style";

export interface AdUserLookupFieldProps {
    selectedUser?: AdUser;
    onChange?: (item: ILookupValue | undefined) => void;
    disabled?: boolean;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const AdUserLookupField = (props: AdUserLookupFieldProps) => {
    // const styles = useStyles();
    const api = useAdminApi();

    const onResolveAdUsers = React.useCallback(async (filter: string, tagList?: ILookupValue[] | undefined): Promise<ILookupValue[]>  => {
        const users = await api.getAdUsers(filter);
        return users?.map(x => ({
            key: x.oId,
            name: x.displayName,
            object: x
        })) ?? [];
    }, [api]);

    const onChange = React.useCallback((items?: ILookupValue[]) => {
        const item = items && items.length > 0 ? items[0] : undefined;
        props.onChange?.(item);
    }, [props]);

    const selectedItems: ILookupValue[] = React.useMemo(() => {
        if (!props.selectedUser) return [];
        return [{
            key: props.selectedUser.oId,
            name: props.selectedUser.displayName,
        }]
    }, [props.selectedUser]);

    return (
        <LookupField 
            onResolveSuggestions={onResolveAdUsers} 
            selectedItems={selectedItems}
            onChange={onChange}
            disabled={props.disabled}
            resolveDelay={500}
        />
    );
};