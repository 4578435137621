import i18next from "i18next";

const translations = (): void => {
  i18next.addResourceBundle("de", "translation", {
    languages: {
      "de": "Deutsch",
      "en": "Englisch",
      "fr": "Französisch",
    },
    app: {
      title: "CLAAS Vorlagen",
      toggleLogo: "Logo anzeigen",
      warningWebVersion: "Achtung, die Web-Version des Add-Ins unterstützt nicht alle Funktionen." +  
      " Für die optimale Verwendung sollte die Desktop App von Word genutzt werden."
    },
    popups: {
      dataLoss: "Sie haben ungespeicherte Änderungen. Wollen Sie wirklich die aktuelle Seite verlassen?",
    },
    general: {
      profile: {
        profile: "Profil",
        company: "Gesellschaft",
        location: "Standort",
        language: "Lokale Sprache",
        personName: "Name",
        personDepartment: "Abteilung",
        personPhone: "Telefon",
        personMobile: "Mobiltelefon",
        personFax: "Fax",
        personEmail: "E-Mail",
      },
      company: {
        name: "Name",
      },
      location: {
        name: "Name",
      },
      language: {
        name: "Name",
        windowLine: "Adresszeile",
        address: "Adresse",
        zip: "PLZ",
        city: "Ort",
        country: "Land",
        phone: "Telefon",
        phoneText: "Telefon Text",
        mobile: "Mobil",
        mobileText: "Mobil Text",
        fax: "Fax",
        faxText: "Fax Text",
        columnCount: "Anzahl Spalten",
        dateFormat: "Datumsformat",
      },
      template: {
        letter: "Brief",
        fax: "Fax",
        more: "Weitere"
      },
      toasts: {
        update: {
          success: "Änderungen erfolgreich gespeichert",
          fail: "Beim Speichern der Änderungen ist ein Fehler aufgetreten.",
        },
        delete: {
          success: "Erfolgreich gelöscht.",
          fail: "Beim Löschen ist ein Fehler aufgetreten.",
        },
        templateRetrieve: {
          fail: "Beim Laden der Vorlage ist ein Fehler aufgetreten.",
        },
        templateReplacement: {
          success: "Die Platzhalter wurden erfolgreich ersetzt.",
          fail: "Beim Ersetzen der Platzhalter ist ein Fehler aufgetreten.",
        },
        create: {
          success: "{{entity}} Erfolgreich erstellt.",
          fail: "Beim Erstellen von {{entity}} ist ein Fehler aufgetreten.",
        },
      },
      confirm: "Bestätigen",
      cancel: "Abbrechen",
      save: "Speichern",
      delete: "Löschen",
      dependentProfileCount: "Es existieren {{n}} abhängige Profile."
    },
    profileChoice: {
      tooltips: {
        addButton: "Neues Profil anlegen",
        editButton: "Aktuelles bearbeiten",
        saveButton: "Änderungen speichern",
        deleteButton: "Profil löschen",
        cancelButton: "Abbrechen",
      },
      confirmationMessages: {
        deleteProfile: {
          message: "Wollen Sie wirklich das Profil '{{profileName}}' löschen?",
          title: "Profil löschen",
        },
        reApplyProfile: {
          message: "Wollen Sie wirklich das Profil erneut auf das Dokument anwenden? Aktuelle Inhalte werden überschrieben.",
          title: "Profil anwenden",
        },
      },
      labels: {
        reApplyProfile: "Profil erneut anwenden",
        applyProfile: "Profil verwenden",
      }
    },
    newProfileNameDialog: {
      message: "Geben Sie den Namen für das neue Profil an.",
      title: "Profil - Name",
    },
    newCompanyNameDialog: {
      message: "Geben Sie den Namen für die neue Gesellschaft an.",
      title: "Gesellschaft - Name",
    },
    newLocationNameDialog: {
      message: "Geben Sie den Namen für den neuen Standort an.",
      title: "Standort - Name",
    },
    newLanguageNameDialog: {
      language: "Sprache",
      message: "Geben Sie den Namen für die neue Sprache an.",
      title: "Sprache - Name",
    },
    newEntityNameDialog: {
      name: "Name",
    },
    addCompanyButton: {
      label: "Neue Gesellschaft"
    },
    signInButton: {
      loginButton: "Einloggen",
    },
    adminNav: {
      start: "Start",
      user: "Benutzer",
      template: "Vorlagen",
      company: "Gesellschaften"
    },
    userControlPanel: {
      menu: {
        title: "Menü",
        logout: "Ausloggen"
      }
    },
    companiesPageEditView: {
      confirmationMessages: {
        deleteCompany: {
          message: "Wollen Sie wirklich die Gesellschaft '{{companyName}}' löschen?",
          title: "Gesellschaft löschen",
        },
        deleteLocation: {
          message: "Wollen Sie wirklich den Standort  '{{locationName}}' löschen?",
          title: "Standort löschen",
        },
        deleteLanguage: {
          message: "Wollen Sie wirklich die Sprache '{{languageName}}' löschen?",
          title: "Sprache löschen",
        },
        dependentProfiles: "Die Abhängigkeit in den zugehörigen Profilen wird gelöscht."
      }
    },
    genericLists: {
      ariaLabelForSelectionColumn: "Auswahl umschalten",
      ariaLabelForSelectAllCheckbox: "Auswahl umschalten für alle Elemente",
      checkButtonAriaLabel: "Zeile auswählen",
      newButtonText: "Neu",
      newButtonAriaLabel: "Neu",
      deleteButtonText: "Löschen",
      deleteButtonAriaLabel: "Löschen",
      editButtonText: "Bearbeiten",
      editButtonAriaLabel: "Bearbeiten"
    },
    companiesPageEditCompanyView: {
      title: "Gesellschaft bearbeiten"
    },
    companiesPageEditLocationView: {
      title: "Standort bearbeiten"
    },
    companiesPageEditLanguageView: {
      title: "Sprache bearbeiten",
      footerTitle: "Fußzeile",
    },
    usersPage: {
      columns: {
        id: "ID",
        name: "Name",
        role: "Rolle"
      },
      saveButton: "Speichern",
    },
    roles: {
      user: "Benutzer",
      globalAdmin: "Administrator",
      companyAdmin: "Gesellschaftsadministrator",
    },
    userEditModal: {
      name: "Name",
      mail: "E-Mail",
      oId: "AAD-OID",
      role: "Rolle",
      managedCompanies: "Verwaltete Gesellschaften",
      editUserTitle: "Benutzer editieren",
      createUserTitle: "Neuen Benutzer erstellen",
      companies: "Verwaltete Gesellschaften",
    },
    templatePage: {
      columns: {
        id: "ID",
        name: "Name",
        category: "Kategorie",
        sharePointId: "SharePoint ID"
      }
    },
    templateNewModal: {
      header: "Neue Vorlage",
      name: "Name",
      category: "Kategorie",
    },
    templateCategory: {
      letter: "Brief",
      fax: "Fax",
      other: "Weitere"
    },
    templateButtons: {
      cancelButton: "Abbrechen",
      saveButton: "Speichern"
    },
    dropzone: {
      dropMessage: "Ziehen Sie eine Datei hier hin, oder klicken Sie um eine Datei auzuwählen",
      dropNotice: "Nur *.dotx Dateien werden akzeptiert",
      invalidType: "Der Typ der Datei wird nicht akzeptiert.",
      tooLarge: "Die Datei ist zu groß.",
      tooSmall: "Die Datei ist zu klein.",
      tooMany: "Es sind zu viele Dateien.",
      defaultMessage: "Es ist ein unerwarteter Fehler aufgetreten.",
      fileHeader: "Datei"
    },
    debugLog: {
      showDebugLog: "Zeige Debug-Log",
      clearLog: "Log leeren",
      verbose: "Detail",
      info: "Info",
      warning: "Warnung",
      error: "Fehler",
      critical: "Kritisch",
      unknown: "Unbekannt",
      logMessagePanelTitle: "Lognachricht",
      logMessagePanelCloseButton: "Schließen",
      logPanelMessage: "Nachricht",
      logPanelSeverity: "Schweregrad",
      logPanelTimestamp: "Zeitstempel",
    }
  });
};

export default translations;
