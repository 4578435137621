import { ILabelProps, ITag, Label, TagPicker } from "@fluentui/react";
import * as React from "react";

export interface ILookupValue extends ITag {
    object?: any;
}

export interface ILookupFieldProps {
    className?: string;
    label?: string;
    onResolveSuggestions: (filter: string, tagList?: ILookupValue[]) => ILookupValue[] | Promise<ILookupValue[]>;
    onEmptyResolveSuggestions?: (tagList?: ILookupValue[]) => ILookupValue[] | Promise<ILookupValue[]>;
    resolveDelay?: number;
    onChange?: (items?: ILookupValue[]) => void;
    selectedItems: ILookupValue[];
    itemLimit?: number | undefined;
    type?: React.HTMLInputTypeAttribute;
    disabled?: boolean;
    doNotLayerCallout?: boolean;
    labelProps?: ILabelProps;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function LookupField(props: ILookupFieldProps) {
    //const styles = useStyles();
    /*const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
        noResultsFoundText: 'No item found',
    };*/

    return (
        <>
            <Label {...props.labelProps} htmlFor="picker1">{props.label}</Label>
            <TagPicker
                disabled={props.disabled}
                className={props.className}
                removeButtonAriaLabel="Remove"
                onEmptyResolveSuggestions={props.onEmptyResolveSuggestions}
                onResolveSuggestions={props.onResolveSuggestions}
                resolveDelay={props.resolveDelay}
                onChange={props.onChange}
                getTextFromItem={(item: ILookupValue) => item.name}
                itemLimit={props.itemLimit}
                // this option tells the picker's callout to render inline instead of in a new layer
                pickerCalloutProps={{ doNotLayer: props.doNotLayerCallout ?? true }}
                selectedItems={props.selectedItems}
                inputProps={{
                    id: "picker1",
                    type: props.type ?? "text",
                }}
            
            />
        </>
    );
}