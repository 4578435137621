import { makeStyles } from "@fluentui/react";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let style = makeStyles((theme) => ({
        columnSelection: {
            padding: "10px 0px 20px 0px",
            maxWidth: "100px"
        },
        footerSection: {
            padding: "18px 0px 8px 0px",
        }
    }));
    return style();
};