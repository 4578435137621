import create from 'zustand';
import { Company } from '../../model/Company';
import { CurrentCompanyItem, ICurrentCompanyItem } from '../../model/ICurrentCompanyItem';
import { Location } from '../../model/Location';
import { LocationLanguage } from '../../model/LocationLanguage';
import { Nullable, NullableOptional } from '../../model/nullable';


interface IEditedCompanyItem {
    type: "Company" | "Location" | "LocationLanguage";
    item: NullableOptional<CurrentCompanyItem>;
}

interface AdminState {
    companies: Company[] | undefined;
    displayName: string | undefined;
    currentCompanyItem: ICurrentCompanyItem | undefined;
    addedCompanyItem: ICurrentCompanyItem | undefined;
    editedCompanyItem: IEditedCompanyItem | undefined;
    setCompanies: (companies: Company[] | undefined) => void;
    addLocation: (companyId: number, location: Location) => void;
    addLanguage: (companyId: number, locationId: number, language: LocationLanguage) => void;
    updateCompany: (company: Company) => void;
    deleteCompany: (id: number) => void;
    updateLocation: (location: Location) => void;
    deleteLocation: (id: number, companyId: number) => void;
    updateLanguage: (language: LocationLanguage) => void;
    deleteLanguage: (id: number, locationId: number) => void;
    setDisplayName: (displayName: string) => void;
    onLoadLocationLangauge: (id: number, language: LocationLanguage) => void;
    setCurrentCompanyItem: (item: ICurrentCompanyItem | undefined) => void;
    setAddedCompanyItem: (item: ICurrentCompanyItem | undefined) => void;
    setEditedCompanyItem: (item: IEditedCompanyItem | undefined) => void;
    resetEditedCompanyItem: () => void;
    patchEditedCompanyItem: <T extends CurrentCompanyItem>(item: Partial<Nullable<T>>) => void;
}

export const useAdminStore = create<AdminState>((set, get) => ({
    companies: undefined,
    displayName: undefined,

    currentCompanyItem: undefined,
    addedCompanyItem: undefined,
    editedCompanyItem: undefined,
    setCompanies: (companies: Company[] | undefined) => set(() => ({companies: companies})),
    addLocation: (companyId: number, location: Location) => set(() => {
        const companies = get().companies;
        if (!companies) return {};
        const newCompanies = [...companies]
        newCompanies?.forEach((c, i) => {
            if (c.id === companyId) newCompanies[i].locations = [...(c.locations ?? []), location];
        })
        return {companies: newCompanies};
    }),
    addLanguage: (companyId: number, locationId: number, language: LocationLanguage) => set(() => {
        const companies = get().companies;
        if (!companies) return {};
        const newCompanies = [...companies]
        const company = newCompanies.find(c => c.id === companyId);
        if (!company || !company.locations) return {};
        company?.locations?.forEach((lo, i) => {
            if(lo.id === locationId){
                (company.locations as Location[])[i] = {...lo, languages: [...(lo.languages ?? []), language]}
            }
        })
        return {companies: newCompanies};
    }),
    updateCompany: (company: Company) => set(() => {
        const companies = get().companies;
        if (!companies) return {};
        const newCompanies = [...companies]
        let updateCompanyIndex = newCompanies.findIndex(c => c.id === company.id);
        if (updateCompanyIndex < 0) return {};

        newCompanies[updateCompanyIndex] = {...company};
        const currentCompanyItem = get().currentCompanyItem;
        const newCurrentCompanyItem = 
            currentCompanyItem?.type === "Company" && currentCompanyItem.item.id === company.id 
            ? {type: "Company", item: company} as ICurrentCompanyItem
            : currentCompanyItem
        get().setCurrentCompanyItem(newCurrentCompanyItem);
        return {companies: newCompanies};
    }),
    deleteCompany: (id: number) => set(() => {
        const companies = get().companies;
        if (!companies) return {};
        const newCompanies = [...companies]
        let updateCompanyIndex = newCompanies.findIndex(c => c.id === id);
        if (updateCompanyIndex < 0) return {};

        const currentCompanyItem = get().currentCompanyItem;
        const newCurrentCompanyItem = 
            currentCompanyItem?.type === "Company" && currentCompanyItem.item.id === id 
            ? updateCompanyIndex === 0 ? undefined : {type: "Company", item: companies[updateCompanyIndex-1]} as ICurrentCompanyItem 
            : currentCompanyItem

        newCompanies.splice(updateCompanyIndex, 1);
        get().setCurrentCompanyItem(newCurrentCompanyItem);
        return {companies: newCompanies};
    }),
    updateLocation: (location: Location) => set(() => {
        const companies = get().companies;
        if (!companies) return {};
        const newCompanies = [...companies];
        const company = newCompanies.find(c => c.id === location.companyId);
        let updateLocationIndex = company?.locations?.findIndex(c => c.id === location.id) ?? -1;
        if (updateLocationIndex >= 0 && company && company.locations){
            company.locations[updateLocationIndex] = {...location};            
            const currentCompanyItem = get().currentCompanyItem;
            const newCurrentCompanyItem = 
                currentCompanyItem?.type === "Location" && currentCompanyItem.item.id === location?.id 
                ? {type: "Location", item: location} as ICurrentCompanyItem
                : currentCompanyItem
            get().setCurrentCompanyItem(newCurrentCompanyItem);
            return {companies: newCompanies};
        }
        return {};
    }),
    deleteLocation: (id: number, companyId: number) => set(() => {
        const companies = get().companies;
        if (!companies) return {};
        const newCompanies = [...companies];
        const company = newCompanies.find(c => c.id === companyId);
        let updateLocationIndex = company?.locations?.findIndex(c => c.id === id) ?? -1;
        if (updateLocationIndex >= 0 && company && company.locations){
            const currentCompanyItem = get().currentCompanyItem;
            const newCurrentCompanyItem = 
                currentCompanyItem?.type === "Location" && currentCompanyItem.item.id === id 
                ? updateLocationIndex === 0 ? undefined : {type: "Location", item: company.locations[updateLocationIndex-1]} as ICurrentCompanyItem 
                : currentCompanyItem

            company.locations.splice(updateLocationIndex, 1);            
            get().setCurrentCompanyItem(newCurrentCompanyItem);
            return {companies: newCompanies};
        }
        return {};
    }),
    updateLanguage: (language: LocationLanguage) => set(() => {
        const companies = get().companies;
        if (!companies) return {};
        const newCompanies = [...companies];
        for (const company of newCompanies) {
            const location = company.locations?.find(c => c.id === language.locationId);
            let updateLanguageIndex = location?.languages?.findIndex(la => la.id === language.id) ?? -1;
            if (location && location.languages && updateLanguageIndex >= 0){
                location.languages[updateLanguageIndex] = {...language};
                const currentCompanyItem = get().currentCompanyItem;
                const newCurrentCompanyItem = 
                    currentCompanyItem?.type === "LocationLanguage" && currentCompanyItem.item.id === language.id 
                    ? {type: "LocationLanguage", item: language} as ICurrentCompanyItem
                    : currentCompanyItem
                get().setCurrentCompanyItem(newCurrentCompanyItem);
                return {companies: newCompanies};
            }
        }
        
        return {};
    }),
    deleteLanguage: (id: number, locationId: number) => set(() => {
        const companies = get().companies;
        if (!companies) return {};
        const newCompanies = [...companies];
        for (const company of newCompanies) {
            const location = company.locations?.find(c => c.id === locationId);
            let updateLanguageIndex = location?.languages?.findIndex(la => la.id === id) ?? -1;
            if (location && location.languages && updateLanguageIndex >= 0){
                const currentCompanyItem = get().currentCompanyItem;
                const newCurrentCompanyItem = 
                    currentCompanyItem?.type === "LocationLanguage" && currentCompanyItem.item.id === id 
                    ? updateLanguageIndex === 0 ? undefined : {type: "LocationLanguage", item: location.languages[updateLanguageIndex-1]} as ICurrentCompanyItem 
                    : currentCompanyItem
        
                    
                location.languages.splice(updateLanguageIndex, 1);
                get().setCurrentCompanyItem(newCurrentCompanyItem);
                return {companies: newCompanies};
            }
        }
        return {};
    }),
    setDisplayName: (displayName: string) => set(() => ({displayName: displayName})),

    onLoadLocationLangauge: (id: number, language: LocationLanguage) => set(() => {
        const companies = get().companies;
        if (!companies) return {companies: companies};
        const newCompanies = [...companies]
        newCompanies?.forEach(y => {
            y.locations?.forEach(z => {
                z.languages = z.languages?.map(z => z.id === id ? {...language, id: z.id, isLoaded: true} as LocationLanguage : z);
            })
        });
        return {companies: newCompanies};
    }),
    setCurrentCompanyItem: (item: ICurrentCompanyItem | undefined) => set(() => (
        {
            currentCompanyItem: item, 
            editedCompanyItem: item ? {type: item.type, item: {id: item.item.id}} : undefined
        } 
    )),
    setAddedCompanyItem: (item: ICurrentCompanyItem | undefined) => set(() => ({addedCompanyItem: item})),
    setEditedCompanyItem: (item: IEditedCompanyItem | undefined) => set(() => ({editedCompanyItem: item})),
    resetEditedCompanyItem: () => set(() => {
        const currentItem = get().currentCompanyItem;
        if (currentItem){
            const newEditedCompanyItem = {...currentItem, item: {id: currentItem.item.id}};
            return {editedCompanyItem: newEditedCompanyItem,};
        }
        return {};
    }),
    patchEditedCompanyItem: <T extends CurrentCompanyItem>(item: Partial<Nullable<T>>) => set(() => {
        const currentItem = get().editedCompanyItem;
        if (currentItem){
            const newEditedCompanyItem = {...currentItem, item: {...currentItem.item ?? {}, ...item, id: currentItem.item.id}}
            let k: keyof typeof item;
            for(k in item){
                let key = k.toString();
                if (item[k] === null && (get().currentCompanyItem?.item[key as keyof CurrentCompanyItem]) === undefined){
                    delete newEditedCompanyItem.item[k as keyof T];
                }
            }
            return {editedCompanyItem: newEditedCompanyItem};
        }
        return {};
    }),
}))