import { createTheme, IButtonProps, PrimaryButton } from "@fluentui/react";
import React from "react";

export interface DeleteButtonProps extends IButtonProps{
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const DeleteButton = (props: DeleteButtonProps) => {
    const generateButtonTheme = () => createTheme({
        palette: {
            themePrimary: '#ff0000',
            themeLighterAlt: '#fff5f5',
            themeLighter: '#ffd6d6',
            themeLight: '#ffb3b3',
            themeTertiary: '#ff6666',
            themeSecondary: '#ff1f1f',
            themeDarkAlt: '#e60000',
            themeDark: '#c20000',
            themeDarker: '#8f0000',
            neutralLighterAlt: '#faf9f8',
            neutralLighter: '#f3f2f1',
            neutralLight: '#edebe9',
            neutralQuaternaryAlt: '#e1dfdd',
            neutralQuaternary: '#d0d0d0',
            neutralTertiaryAlt: '#c8c6c4',
            neutralTertiary: '#a19f9d',
            neutralSecondary: '#605e5c',
            neutralPrimaryAlt: '#3b3a39',
            neutralPrimary: '#323130',
            neutralDark: '#201f1e',
            black: '#000000',
            white: '#ffffff',
        },
    });
    
    return (
        <PrimaryButton {...props} theme={generateButtonTheme()} />
    );
};