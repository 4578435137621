import * as React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { RoleType } from "../../../model/RoleType";
import { Constants } from "../../Constants";
import { useSessionStore } from "../../state/useSessionStore";
import useStyles from "./NavMenu.styles";

export interface NavMenuProps {
}

export default function NavMenu(props: NavMenuProps) {
    const styles = useStyles();
    const { t } = useTranslation("translation", { keyPrefix: "adminNav" });
    const { currentUser } = useSessionStore();
    const navItems: { id: string; displayText: string; link: string; }[] = React.useMemo(() => {
        if (currentUser?.userRoles?.find((r) => r.roleId === RoleType.GlobalAdmin || r.roleId === RoleType.CompanyAdmin)) {
            return [
                {
                    id: "start",
                    displayText: t("start"),
                    link: Constants.urls.start,
                },
                {
                    id: "user",
                    displayText: t("user"),
                    link: Constants.urls.user,
                },
                {
                    id: "template",
                    displayText: t("template"),
                    link: Constants.urls.template,
                },
                {
                    id: "company",
                    displayText: t("company"),
                    link: Constants.urls.company,
                },
            ];
        }
        return [];
    }, [currentUser, t])

    return (
            <div className={styles.navMenu}>
                {navItems.map(navItem =>
                    <NavLink
                        to={navItem.link}
                        key={navItem.id}
                        className={({ isActive }) =>
                            isActive ? styles.activeNavItem : styles.navItem
                        }
                    >
                        {navItem.displayText}
                    </NavLink>)}
            </div>
    );
}