import { makeStyles } from "@fluentui/react";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    let style = makeStyles((theme) => ({
        header: {
            margin: "0.25em",
            marginTop: "0",
        },
        body: {
            margin: "0.25em",
        }
    }));
    return style();
};