import React from "react";
import { useApi } from "../../api/useApi";
import { useAdminStore } from "../state/useAdminState";

export const useLoadLocationLanguage = () => {
    const {getLanguage} = useApi();
    const {companies, onLoadLocationLangauge} = useAdminStore();
    return React.useCallback(async (id: number, forceReload?: boolean) => {
        const languages = companies?.flatMap(c => c.locations).flatMap(lo => lo?.languages);
        let result = languages?.find(la => la && la.id === id);
        if (result?.isLoaded && !forceReload) return result;

        result = await getLanguage(id);
        if (!result) return undefined;
        
        onLoadLocationLangauge(id, result);
        return result;
    }, [companies, getLanguage, onLoadLocationLangauge]);
}

export default useLoadLocationLanguage;