import { makeStyles } from "@fluentui/react";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let style = makeStyles((theme) => ({
        userList: {
            display: "flex",
            flexDirection: "column"

        },
        list: {

        },
        searchBox: {

        }

    }));
    return style();
};