import i18next from "i18next";

const translations = (): void => {
  i18next.addResourceBundle("en", "translation", {
    languages: {
      "de": "German",
      "en": "English",
      "fr": "French",
    },
    app: {
      title: "CLAAS Templates",
      useTemplate: "Apply template",
      toggleLogo: "Show logo",
      warningWebVersion: "Attention, the web version of the add-in does not support all functions." +  
      "For optimal use, the Word desktop app should be used.",
    },
    popups: {
      dataLoss: "You have unsaved changes. Do you really want to leave the current page?",
    },
    general: {
      profile: {
        profile: "Profile",
        company: "Company",
        location: "Location",
        language: "Local language",
        personName: "Name",
        personDepartment: "Department",
        personPhone: "Phone",
        personMobile: "Mobile",
        personFax: "Fax",
        personEmail: "E-Mail",
      },
      company: {
        name: "Name",
      },
      location: {
        name: "Name",
      },
      language: {
        name: "Name",
        windowLine: "Window line",
        address: "Address",
        zip: "Zip",
        city: "City",
        country: "Country",
        phone: "Phone",
        phoneText: "Phone Text",
        mobile: "Mobile",
        mobileText: "Mobile Text",
        fax: "Fax",
        faxText: "Fax Text",
        columnCount: "Footer column count",
        dateFormat: "date format",
      },
      templates: {
        letter: "Letter",
        fax: "Fax",
        more: "More"
      },
      toasts: {
        update:{
          success: "Changes haven been saved successfully.",
          fail: "While saving the changes an error occurred.",
        },
        delete: {
          success: "Deletion successful.",
          fail: "While deleting an error occurred.",
        },
        templateRetrieve: {
          fail: "While loading the template an error occurred.",
        },
        templateReplacement: {
          success: "Placeholders have been replaced successfully.",
          fail: "While replacing the placeholders an error occurred.",
        },
        create: {
          success: "{{entity}} created successfully.",
          fail: "While creating {{entity}} an error occurred.",
        },
      },
      confirm: "Confirm",
      cancel: "Cancel",
      save: "Save",
      delete: "Delete",
      dependentProfileCount: "There are {{n}} dependent profiles.",
    },
    profileChoice: {
      tooltips: {
        addButton: "Create a new profile",
        editButton: "Edit profile",
        saveButton: "Save changes",
        deleteButton: "Delete profile",
        cancelButton: "Cancel",
      },
      confirmationMessages: {
        deleteProfile:{
          message: "Do you really want to delete the profile '{{profileName}}'?",
          title: "Delete Profile",
        },
        reApplyProfile:{
          message: "Do you really want to reapply the profile to the document? Current content will be overwritten.",
          title: "Re-apply profile",
        },
      },
      labels: {
        reApplyProfile: "Re-apply profile."
      }
    },
    newProfileNameDialog: {
      message: "Enter the name of the new profile",
      title:"Profile - Name",
    },
    newCompanyNameDialog: {
      message: "Enter the name of the new company",
      title:"Company - Name",
    },
    newLocationNameDialog: {
      message: "Enter the name of the new location",
      title:"Location - Name",
    },
    newLanguageNameDialog: {
      language: "Language",
      message: "Enter the name of the new language",
      title:"Language - Name",
    },
    newEntityNameDialog: {
      name: "Name",
    },
    addCompanyButton: {
      label: "New company"
    },
    signInButton: {
      loginButton: "Login",
    },
    adminNav: {
      start: "Start",
      user: "User",
      template: "Templates",
      company: "Companies"
    },
    userControlPanel: {
      menu: {
        title: "Menu",
        logout: "Logout"
      }
    },
    companiesPageEditView: {
      deleteCompany:{
        message: "Do you really want to delete the Company '{{companyName}}'?",
        title: "Delete Company",
      },
      deleteLocation:{
        message: "Do you really want to delete the Location '{{locationName}}'?",
        title: "Delete Location",
      },
      deleteLanguage:{
        message: "Do you really want to delete the Language '{{languageName}}'?",
        title: "Delete Language",
      }
    },
    dependentProfiles: "The dependency in the associated profiles will be deleted.",
    genericLists: {
      ariaLabelForSelectionColumn: "Toggle selection",
      ariaLabelForSelectAllCheckbox: "Toggle selection for all items",
      checkButtonAriaLabel: "Select row",
      newButtonText: "New",
      newButtonAriaLabel: "New",
      deleteButtonText: "Delete",
      deleteButtonAriaLabel: "Delete",
      editButtonText: "Edit",
      editButtonAriaLabel: "Edit"
    },
    companiesPageEditCompanyView: {
      title: "Edit company"
    },
    companiesPageEditLocationView: {
      title: "Edit location"
    },
    companiesPageEditLanguageView: {
      title: "edit language",
      footerTitle: "Footer",
    },
    usersPage: {
      columns: {
        id: "ID",
        name: "Name",
        role: "Role"
      },
      saveButton: "Save"
    },
    roles: {
      user: "User",
      globalAdmin: "Administrator",
      companyAdmin: "Company Administrator",
    },
    userEditModal:{
      name: "Name",
      mail: "Mail",
      oId: "AAD-OID",
      role: "Role",
      managedCompanies: "Managed Companies",
      editUserTitle: "Edit user",
      createUserTitle: "Create a new user",
      companies: "Managed companies",
    },
    templatePage: {
      columns: {
        id: "ID",
        name: "Name",
        category: "Category",
        sharePointId: "SharePoint ID"
      }
    },
    templateNewModal: {
      header: "New template",
      name: "Name",
      category: "Category",
    },
    templateCategory: {
      letter: "Letter",
      fax: "Fax",
      other: "Other"
    },
    templateButtons: {
        cancelButton: "Cancel",
        saveButton: "Save"
    },
    dropzone: {
      dropMessage: "Drag 'n' drop a file here, or click to select a file",
      dropNotice: "Only *.dotx files will be accepted",
      invalidType: "The type of the file does not get accepted.",
      tooLarge: "The file is too large.",
      tooSmall: "The file is too small.",
      tooMany: "Too many files.",
      defaultMessage: "An error occurred.",
      fileHeader: "File"
    },
    debugLog: {
      showDebugLog: "Show debug log",
      clearLog: "Clear Log",
      verbose: "Verbose",
      info: "Info",
      warning: "Warning",
      error: "Error",
      critical: "Critical",
      unknown: "Unknown",
      logMessagePanelTitle: "Log Message",
      logMessagePanelCloseButton: "Close",
      logPanelMessage: "Message",
      logPanelSeverity: "Severity",
      logPanelTimestamp: "Timestamp",
    }
  });
};

export default translations;
