import { MessageBar, MessageBarType } from '@fluentui/react/lib/MessageBar';
import { css } from "@fluentui/utilities/lib/css";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import useStyles from "./TemplateDropzone.styles";
import { TemplateFileDisplay } from './TemplateFileDisplay';

export interface TemplateDropzoneProps {
    setTemplateFile: (file: File | undefined) => void;
    templateFileId: number | undefined;
    templateFileName: string | undefined;
    isOpen?: boolean;
    setHasTemplateFileChanged?: (b: boolean) => void;
}

export const TemplateDropzone = (props: TemplateDropzoneProps): JSX.Element => {


    const styles = useStyles();

    const { t } = useTranslation("translation", { keyPrefix: "dropzone" });
    const [isNewFile, setIsNewFile] = useState<boolean>(false);
    const [isRejected, setIsRejected] = useState<boolean>(false);
    const [errMessage, setErrMessage] = useState<string>("");

    const onDrop = useCallback((acceptedFiles: File[]) => {
        props.setTemplateFile(acceptedFiles[0]);
        setIsNewFile(true);
        props.setHasTemplateFileChanged?.(true);
    }, []);

    const {
        acceptedFiles,
        isFocused,
        isDragAccept,
        isDragReject,
        fileRejections,
        getRootProps, getInputProps
    } = useDropzone({
        accept: { 'application/vnd.openxmlformats-officedocument.wordprocessingml.template': ['.dotx'] },
        maxFiles: 1,
        onDrop: onDrop,
    })

    const filePickerClass = useMemo(() => css(
        styles.baseStyle,
        isFocused ? styles.focusedStyle : undefined,
        isDragAccept ? styles.acceptStyle : undefined,
        isDragReject ? styles.rejectStyle : undefined),
        [isDragAccept, isDragReject, isFocused, styles.acceptStyle, styles.baseStyle, styles.focusedStyle, styles.rejectStyle]
    );

    const fileToRender = useMemo(() => {
        if (props.templateFileName && !isNewFile) {
            return (
                <TemplateFileDisplay fileName={props.templateFileName} fileId={props.templateFileId}></TemplateFileDisplay>
            )
        }
        return acceptedFiles.map((file: File) => (
            <TemplateFileDisplay key={"Template Dropzone" + file.name} fileName={file.name}></TemplateFileDisplay>
        ));
    }, [acceptedFiles, isNewFile, props.templateFileId, props.templateFileName]);

    const errorMessage = useCallback(() => {
        switch (fileRejections[0].errors[0].code) {
            case "file-invalid-type": {
                setErrMessage(t("invalidType"));
                break;
            }
            case "file-too-large": {
                setErrMessage(t("tooLarge"));
                break;
            }
            case "file-too-small": {
                setErrMessage(("tooSamall"));
                break;
            }
            case "too-many-files": {
                setErrMessage(t("tooMany"));
                break;
            }
            default: {
                setErrMessage(t("defaultMessage"));
            }
        }

    }, [fileRejections, t]);

    useEffect(() => {
        if (fileRejections.length) {
            errorMessage();
            console.log(fileRejections[0].errors[0].code);
            setIsRejected(true);
        }
    }, [errorMessage, fileRejections])

    const dismissMessage = useCallback(() => {
        setIsRejected(false);
    }, []);

    return (
        <div className="container">
            {isRejected && <MessageBar className={styles.errBarStyle} delayedRender={false} messageBarType={MessageBarType.error} onDismiss={dismissMessage} dismissButtonAriaLabel="Close"> {errMessage} </MessageBar>}
            <div {...getRootProps({ className: filePickerClass })} >
                <input {...getInputProps()} />
                <p>{t("dropMessage")}</p>
                <em>({t("dropNotice")})</em>
            </div>
            <aside>
                <h4>{t("fileHeader")}</h4>
                <ul>{fileToRender}</ul>
            </aside>
        </div>
    );
}