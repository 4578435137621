import { ShimmerCircle } from "@fluentui/react";
import React, { useMemo } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSessionStore } from "../../state/useSessionStore";
// import { useAuth } from "../hooks/useAuth";

export const ProtectedRoute = (props: React.PropsWithChildren<{}>) => {
    const location = useLocation();
    const { currentUser, loadingState } = useSessionStore();
    
    const content = useMemo(()=>{
        if(loadingState === "Loading" || loadingState === "NotLoaded"){
            return (<ShimmerCircle />)
        }
        if(loadingState === "Failure" || (loadingState === "Loaded" && (!currentUser?.userRoles || currentUser?.userRoles.length === 0))){
            
            return <Navigate to="/unauthorized" replace state={{ from: location }} />;
        }
        return (<>{props.children}</>);
    },[currentUser, loadingState, location, props.children]);

   return (<>{content}</>)
};