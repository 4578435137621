import { Dropdown, IDropdownOption, MessageBar, Stack, Text, TextField } from "@fluentui/react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { LocationLanguage } from "../../../../model/LocationLanguage";
import { nullIfEmpty } from "../../../../utils/undefinedIfEmpty";
import { useAdminApi } from "../../../api/useAdminApi";
import { useAdminStore } from "../../../state/useAdminState";
import useStyles from "./CompaniesPageEditLanguageView.styles";


function getLongestLineFromFormattedText(text: string) {
    const array = text.split("\n");
    const t = array.reduce((a, b) => a.length > b.length ? a : b);
    return t;
}

export interface CompaniesPageEditLanguageViewProps {
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const CompaniesPageEditLanguageView = (props: CompaniesPageEditLanguageViewProps) => {
    const styles = useStyles();
    const { t } = useTranslation("translation");
    const store = useAdminStore();
    const api = useAdminApi();
    const entityToShow = React.useMemo(() => {
        if (store.currentCompanyItem?.type === "LocationLanguage") {
            return { ...store.currentCompanyItem?.item, ...store.editedCompanyItem?.item } as LocationLanguage;
        }
    }, [store.currentCompanyItem?.item, store.currentCompanyItem?.type, store.editedCompanyItem?.item]);

    React.useEffect(() => {
        const determineProfiles = async () => {
            if (!store.currentCompanyItem?.item || store.currentCompanyItem.item.quantityDependentProfiles !== undefined) return;
            const count = await api.getLanguageDependentProfileCount(store.currentCompanyItem?.item.id ?? -1);
            const language = store.currentCompanyItem.item;
            language.quantityDependentProfiles = count;
            store.updateLanguage(language as LocationLanguage);
        }
        void determineProfiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.currentCompanyItem?.item.id]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const columnOptions: IDropdownOption[] = [
        {
            key: 3,
            text: "3"
        },
        {
            key: 4,
            text: "4"
        },
    ];
    const dateOptions: IDropdownOption[] = [
        {
            key: 1,
            text: "DD.MM.YYYY"
        },
        {
            key: 2,
            text: "MM.DD.YYYY"
        },
    ];

    const getColumnStyle = (text: string) => {
        const maxLine = getLongestLineFromFormattedText(text);
        const length = maxLine.length;
        const pixelWidth = length * 8;
        return {
            root: {
                minWidth: `${pixelWidth}px`
            }
        }
    };

    const renderColumnSelection = React.useMemo(() => {
        return (<Dropdown
            className={styles.columnSelection}
            label={t("general.language.columnCount")}
            selectedKey={entityToShow?.footerColumnCount}
            options={columnOptions}
            onChange={(_ev, newValue) => store.patchEditedCompanyItem<LocationLanguage>({ footerColumnCount: (newValue == null || "") ? null : Number.parseInt(newValue.key as string) })}
        />)
    }, [columnOptions, entityToShow?.footerColumnCount, store, styles.columnSelection, t]);

    return (
        <div>
            <Text
                variant="xLargePlus">{t("companiesPageEditLanguageView.title")}
            </Text>
            { (store.currentCompanyItem?.item.quantityDependentProfiles ?? 0) > 0 &&
                <MessageBar>
                    {t("general.dependentProfileCount", {n: store.currentCompanyItem?.item.quantityDependentProfiles})}
                </MessageBar>
            }
            <Stack styles={{root: {paddingTop: "1em"}}} horizontal={true} tokens={{ childrenGap: "2em" }} >
                <Stack.Item>
                    <Stack styles={{ root: { paddingTop: "1em" } }} tokens={{ childrenGap: 8 }}>
                        <Stack.Item>
                            <TextField
                                disabled
                                label={t("general.language.name")}
                                value={entityToShow?.name ?? ""}
                                onChange={(_ev, newValue) => store.patchEditedCompanyItem<LocationLanguage>({ name: nullIfEmpty(newValue) })}
                            >
                            </TextField>
                        </Stack.Item>
                        <Stack.Item>
                            <TextField
                                label={t("general.language.windowLine")}
                                value={entityToShow?.windowLine ?? ""}
                                onChange={(_ev, newValue) => store.patchEditedCompanyItem<LocationLanguage>({ windowLine: nullIfEmpty(newValue) })}
                            >
                            </TextField>
                        </Stack.Item>
                        <Stack.Item>
                            <TextField
                                label={t("general.language.address")}
                                value={entityToShow?.address ?? ""}
                                onChange={(_ev, newValue) => store.patchEditedCompanyItem<LocationLanguage>({ address: nullIfEmpty(newValue) })}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <Dropdown
                                
                                label={t("general.language.dateFormat")}
                                selectedKey={entityToShow?.dateFormat}
                                options={dateOptions}
                                onChange={(_ev, newValue) => store.patchEditedCompanyItem<LocationLanguage>({ dateFormat: (newValue == null || "") ? null : Number.parseInt(newValue.key as string) })}
                            />
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Stack styles={{ root: { paddingTop: "1em" } }} tokens={{ childrenGap: 8 }}>
                        <Stack.Item>
                            <TextField
                                label={t("general.language.zip")}
                                value={entityToShow?.zip ?? ""}
                                onChange={(_ev, newValue) => store.patchEditedCompanyItem<LocationLanguage>({ zip: nullIfEmpty(newValue) })}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <TextField
                                label={t("general.language.city")}
                                value={entityToShow?.city ?? ""}
                                onChange={(_ev, newValue) => store.patchEditedCompanyItem<LocationLanguage>({ city: nullIfEmpty(newValue) })}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <TextField
                                label={t("general.language.country")}
                                value={entityToShow?.country ?? ""}
                                onChange={(_ev, newValue) => store.patchEditedCompanyItem<LocationLanguage>({ country: nullIfEmpty(newValue) })}
                            />
                        </Stack.Item>

                    </Stack>
                </Stack.Item>
                <Stack styles={{ root: { paddingTop: "1em" } }} tokens={{ childrenGap: 8 }}>
                    <Stack.Item>
                        <TextField
                            label={t("general.language.phoneText")}
                            value={entityToShow?.phoneText ?? ""}
                            onChange={(_ev, newValue) => store.patchEditedCompanyItem<LocationLanguage>({ phoneText: nullIfEmpty(newValue) })}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField
                            label={t("general.language.mobileText")}
                            value={entityToShow?.mobileText ?? ""}
                            onChange={(_ev, newValue) => store.patchEditedCompanyItem<LocationLanguage>({ mobileText: nullIfEmpty(newValue) })}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField
                            label={t("general.language.faxText")}
                            value={entityToShow?.faxText ?? ""}
                            onChange={(_ev, newValue) => store.patchEditedCompanyItem<LocationLanguage>({ faxText: nullIfEmpty(newValue) })}
                        />
                    </Stack.Item>
                </Stack>
                <Stack styles={{ root: { paddingTop: "1em" } }} tokens={{ childrenGap: 8 }}>
                    <Stack.Item>
                        <TextField
                            label={t("general.language.phone")}
                            value={entityToShow?.phone ?? ""}
                            onChange={(_ev, newValue) => store.patchEditedCompanyItem<LocationLanguage>({ phone: nullIfEmpty(newValue) })}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField
                            label={t("general.language.mobile")}
                            value={entityToShow?.mobile ?? ""}
                            onChange={(_ev, newValue) => store.patchEditedCompanyItem<LocationLanguage>({ mobile: nullIfEmpty(newValue) })}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField
                            label={t("general.language.fax")}
                            value={entityToShow?.fax ?? ""}
                            onChange={(_ev, newValue) => store.patchEditedCompanyItem<LocationLanguage>({ fax: nullIfEmpty(newValue) })}
                        />
                    </Stack.Item>
                </Stack>
            </Stack>
            <Stack className={styles.footerSection}>
                <Stack.Item>
                    <hr></hr>
                    <Text
                        variant="large">{t("companiesPageEditLanguageView.footerTitle")}
                    </Text>
                    {renderColumnSelection}
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal={true} tokens={{ childrenGap: "2em" }} >
                        {entityToShow?.footerColumnCount != 0 && <Stack.Item>
                            <TextField
                                styles={getColumnStyle(entityToShow?.footerColumn1 ?? "")}
                                value={entityToShow?.footerColumn1 ?? ""}
                                onChange={(_ev, newValue) => store.patchEditedCompanyItem<LocationLanguage>({ footerColumn1: nullIfEmpty(newValue) })}
                                multiline
                                autoAdjustHeight={true}
                            />
                        </Stack.Item>}
                        {entityToShow?.footerColumnCount != 0 && <Stack.Item>
                            <TextField
                                styles={getColumnStyle(entityToShow?.footerColumn2 ?? "")}
                                value={entityToShow?.footerColumn2 ?? ""}
                                onChange={(_ev, newValue) => store.patchEditedCompanyItem<LocationLanguage>({ footerColumn2: nullIfEmpty(newValue) })}
                                multiline
                                autoAdjustHeight={true}
                            />
                        </Stack.Item>}
                        {(entityToShow?.footerColumnCount != null && entityToShow.footerColumnCount >= 3) && <Stack.Item>
                            <TextField
                                styles={getColumnStyle(entityToShow?.footerColumn3 ?? "")}
                                value={entityToShow?.footerColumn3 ?? ""}
                                onChange={(_ev, newValue) => store.patchEditedCompanyItem<LocationLanguage>({ footerColumn3: nullIfEmpty(newValue) })}
                                multiline
                                autoAdjustHeight={true}
                            />
                        </Stack.Item>}
                        {(entityToShow?.footerColumnCount != null && entityToShow.footerColumnCount >= 4) && <Stack.Item>
                            <TextField
                                styles={getColumnStyle(entityToShow?.footerColumn4 ?? "")}
                                value={entityToShow?.footerColumn4 ?? ""}
                                onChange={(_ev, newValue) => store.patchEditedCompanyItem<LocationLanguage>({ footerColumn4: nullIfEmpty(newValue) })}
                                multiline
                                autoAdjustHeight={true}

                            />
                        </Stack.Item>}
                    </Stack>
                </Stack.Item>
            </Stack>
        </div>
    );
};