import React from "react";
import { ICurrentCompanyItem } from "../../model/ICurrentCompanyItem";
import { useAdminStore } from "../state/useAdminState";

export const useSetCurrentCompanyItem = () => {
    // todo replace this with url when adding deeplinks to companiespage
    const { setCurrentCompanyItem } = useAdminStore();
    return React.useCallback((item: ICurrentCompanyItem | undefined) => {
        setCurrentCompanyItem(item)
    }, [setCurrentCompanyItem]);
}

export default useSetCurrentCompanyItem;