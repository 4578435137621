import { makeStyles } from "@fluentui/react";
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let style = makeStyles(() => ({
        root: {
            padding: "1em",
            boxShadow: "rgba(100, 100, 111, 0.2) 1px 7px 29px 1px",
        }
    }));
    return style();
};