import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import create from 'zustand';
import { constants } from '../constants/constants';
import { LogMessage } from '../model/LogMessage';

export interface MonitoringState {
    /**
     * Contains the recent log messages of the application
     */
    logMessages: LogMessage[];
    applicationInsights: ApplicationInsights | undefined;
    setLogMessages: (logMessages: LogMessage[]) => void;
    addLogMessage: (logMessage: LogMessage) => void;
    logCritical: (message: string, optionalParameters?: { [key: string]: unknown; }) => void;
    logError: (message: string, optionalParameters?: { [key: string]: unknown; }) => void;
    logWarning: (message: string, optionalParameters?: { [key: string]: unknown; }) => void;
    logInfo: (message: string, optionalParameters?: { [key: string]: unknown; }) => void;
    logVerbose: (message: string, optionalParameters?: { [key: string]: unknown; }) => void;
}

function initAppInsights() {
    // const browserHistory = createBrowserHistory({});
    let ai = undefined;
    try {
        const rp = new ReactPlugin()
        ai = new ApplicationInsights({
            config: {
                connectionString: constants.appInsightsConnectionString,
                extensions: [rp],
                enableAutoRouteTracking: true,
                // extensionConfig: {
                //     [rp.identifier]: { history: browserHistory }
                // }
            }
        });
        ai.loadAppInsights();
    }
    catch(e){
        console.warn("Error initializing app insights", e)
    }

    return ai;
}

export type LogSeverity = "Exception" | "Error" | "Warning" | "Info" | "Trace";

function severityToString(severity: SeverityLevel): string {
    switch (severity) {
        case SeverityLevel.Verbose:
            return "Verbose";
        case SeverityLevel.Information:
            return "Information";
        case SeverityLevel.Warning:
            return "Warning";
        case SeverityLevel.Error:
            return "Error";
        case SeverityLevel.Critical:
            return "Critical";
        default:
            // eslint-disable-next-line no-case-declarations
            return `Unknown`;
    }
}

export const useMonitoringStore = create<MonitoringState>((set, get) => ({
    logMessages: [],
    applicationInsights: initAppInsights(),
    setLogMessages: (logMessages: LogMessage[]) => set(() => ({ logMessages: logMessages })),
    addLogMessage: (logMessage: LogMessage) => set((state) => {
        console.log(`[${severityToString(logMessage.logLevel)}] ${logMessage.message}`, logMessage.additionalData ?? "");
        state.applicationInsights?.trackTrace({ message: logMessage.message, severityLevel: logMessage.logLevel, properties: logMessage.additionalData });
        return ({ logMessages: [...state.logMessages, logMessage] });
    }),
    logCritical: (message: string, optionalParameters?: { [key: string]: unknown; }) => {
            const log = { logLevel: SeverityLevel.Critical.valueOf(), message: message, additionalData: optionalParameters, timestamp: new Date() } as LogMessage;
            get().addLogMessage(log)
    },
    logError: (message: string, optionalParameters?: { [key: string]: unknown; }) => {
            const log = { logLevel: SeverityLevel.Error.valueOf(), message: message, additionalData: optionalParameters, timestamp: new Date() } as LogMessage;    
            get().addLogMessage(log)
    },
    logWarning: (message: string, optionalParameters?: { [key: string]: unknown; }) => {
            const log = { logLevel: SeverityLevel.Warning.valueOf(), message: message, additionalData: optionalParameters, timestamp: new Date() } as LogMessage;
            get().addLogMessage(log)
    },
    logInfo: (message: string, optionalParameters?: { [key: string]: unknown; }) => {
            const log = { logLevel: SeverityLevel.Information.valueOf(), message: message, additionalData: optionalParameters, timestamp: new Date() } as LogMessage;
            get().addLogMessage(log)
    },
    logVerbose: (message: string, optionalParameters?: { [key: string]: unknown; }) => {
            const log = { logLevel: SeverityLevel.Verbose.valueOf(), message: message, additionalData: optionalParameters, timestamp: new Date() } as LogMessage;
            get().addLogMessage(log)
    },
}));





