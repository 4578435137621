import { BaseButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { ComboBox, IComboBoxOption } from "@fluentui/react/lib/ComboBox";
import { Modal } from "@fluentui/react/lib/Modal";
import { TextField } from "@fluentui/react/lib/TextField";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToasts } from "../../../hooks/useToasts";
import { CategoryType } from "../../../model/CategoryType";
import { Template } from "../../../model/Template";
import { useAdminApi } from "../../api/useAdminApi";
import { TemplateDropzone } from "../TemplateDropzone/TemplateDropzone";
import useStyles from "./TemplateEditModal.styles";



export interface TemplateEditModalProps {
    isOpen: boolean;
    onDismissCancel: () => void;
    onDismissSave: () => void;
    selectedTemplate: Template | undefined;
}

export const TemplateEditModal = (props: TemplateEditModalProps): JSX.Element => {
    const { t } = useTranslation("translation", { keyPrefix: "templateNewModal" });
    const { t: tr } = useTranslation("translation", { keyPrefix: "templateCategory" });
    const { t: tu } = useTranslation("translation", { keyPrefix: "templateButtons" });
    const [currentTemplate, setCurrentTemplate] = useState<Template | undefined>(undefined);
    const [currentId, setCurrentId] = useState<number | undefined>(undefined);
    const [currentName, setCurrentName] = useState<string | undefined>(undefined);
    const [selectedCategory, setSelectedCategory] = useState<CategoryType | undefined>(undefined);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const adminApi = useAdminApi();
    const styles = useStyles();
    const [templateFileId, setTemplateFileId] = useState<number | undefined>(undefined);
    const [templateFileName, setTemplateFileName] = useState<string | undefined>(undefined);
    const [currentTemplateFile, setCurrentTemplateFile] = useState<File | undefined>(undefined);
    const [hasTemplateFileChanged, setHasTemplateFileChanged] = useState<boolean>(false);
    const toasts = useToasts();

    /* eslint-disable react-hooks/exhaustive-deps */
    function getCategoryNameByCategoryType(id: CategoryType | undefined) {
        let roleName = "";
        switch (id) {
            case CategoryType.Letter:
                roleName = tr("letter");
                break;
            case CategoryType.Fax:
                roleName = tr("fax");
                break;
            case CategoryType.Other:
                roleName = tr("other");
                break;
            default:
        }
        return roleName;
    }
    /* eslint-enable react-hooks/exhaustive-deps */

    const isSafeDisabled = useMemo(() => {
        if (currentName === props.selectedTemplate?.name && selectedCategory === props.selectedTemplate?.category && !hasTemplateFileChanged) {
            return true;
        }
        return false;
    }, [currentName, hasTemplateFileChanged, props.selectedTemplate?.category, props.selectedTemplate?.name, selectedCategory]);

    const categoryOptions: IComboBoxOption[] = [
        {
            key: CategoryType.Letter,
            text: tr("letter"),
        },
        {
            key: CategoryType.Fax,
            text: tr("fax"),
        },
        {
            key: CategoryType.Other,
            text: tr("other"),
        },
    ]

    useEffect(() => {
        setCurrentTemplate(props.selectedTemplate);
        setCurrentId(props.selectedTemplate?.id ?? undefined);
        setCurrentName(props.selectedTemplate?.name ?? undefined);
        setSelectedCategory(props.selectedTemplate?.category ?? undefined);
        setTemplateFileId(undefined);
        setTemplateFileName(undefined);
        setCurrentTemplateFile(undefined);
        setCurrentTemplate(props.selectedTemplate);
        setTemplateFileId(props.selectedTemplate?.sharePointId);
        setTemplateFileName(props.selectedTemplate?.sharePointName);
    }, [props.selectedTemplate])

    const onDismissCancel = useCallback(() => {
        props.onDismissCancel();
        setIsOpen(false);

    }, [props]);

    const onDismissSave = useCallback(() => {
        props.onDismissSave();
        setIsOpen(false);

    }, [props]);

    const onSave = useCallback(async () => {
        if (currentId) {
            var data = new FormData();
            if (currentName && currentName !== currentTemplate?.name) data.append("name", currentName);
            if (selectedCategory && selectedCategory !== currentTemplate?.category) data.append("category", getCategoryNameByCategoryType(selectedCategory));
            if (currentTemplateFile && currentTemplateFile.name !== templateFileName) data.append("templateFile", currentTemplateFile, currentTemplateFile.name);
            const addedTemplate = await adminApi.updateTemplate(currentId, data);
            if (addedTemplate != null) {
                if (currentName) toasts.updateSuccess();
            }
            else {
                if (currentName) toasts.updateFailure();
            }
        }

        onDismissSave();

    }, [currentId, onDismissSave, currentName, currentTemplate?.name, currentTemplate?.category, selectedCategory, getCategoryNameByCategoryType, currentTemplateFile, templateFileName, adminApi, toasts]);

    useEffect(() => {
        if (props.isOpen) setIsOpen(true);
    }, [props.isOpen])

    return (
        <div>
            <Modal
                isBlocking={true}
                isOpen={props.isOpen}
                onDismiss={onDismissCancel}
                layerProps={{eventBubblingEnabled: true}}
            >
                <div className={styles.flexWrapper}>
                    <div>
                        <h2>{props.selectedTemplate?.name}</h2>
                    </div>
                    <div>
                        <TextField label={t("name")} value={currentName} onChange={(event, newValue) => setCurrentName(newValue)} ></TextField>
                        <ComboBox
                            label={t("category")}
                            options={categoryOptions}
                            selectedKey={selectedCategory}
                            onChange={(event, option) => setSelectedCategory(option?.key as CategoryType ?? undefined)}
                        />
                    </div>
                    <div>
                        <TemplateDropzone setTemplateFile={setCurrentTemplateFile} templateFileId={templateFileId} templateFileName={templateFileName} isOpen={isOpen} setHasTemplateFileChanged={setHasTemplateFileChanged}></TemplateDropzone>
                    </div>
                    <div className={styles.buttonRow}>
                        <BaseButton className={styles.cancelButton} onClick={onDismissCancel}>{tu("cancelButton")}</BaseButton>
                        <PrimaryButton className={styles.saveButton} onClick={onSave} disabled={isSafeDisabled}>{tu("saveButton")}</PrimaryButton>
                    </div>
                </div>

            </Modal >
        </div >
    )
}


