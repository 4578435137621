import React from "react";
import { Tile } from "../../../components/Layout/Tile/Tile";
import useStyles from "./BaseLayout.styles";

export interface BaseLayoutProps {
    disableCommentPane?: boolean;
    disableNavigationalPrompt?: boolean;
    titleBar?: JSX.Element;
    navMenu?: JSX.Element;
    content?: JSX.Element;

}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const BaseLayout = (props: React.PropsWithChildren<BaseLayoutProps>): JSX.Element => {
    const styles = useStyles();
    return (
        <div className={styles.layout}>
            <div className={styles.titlebar}>
                {props.titleBar}
            </div>
            <div className={styles.navAndBodyWrapper}>
                <div className={styles.navMenuWrapper}>
                    <Tile>
                        <div className={styles.contentTile}>
                            {props.navMenu != null && props.navMenu}
                        </div>

                    </Tile>

                </div>

                <div className={styles.content}>
                    <Tile>
                        <div className={styles.contentTile}>
                            {props.content != null && props.content}
                        </div>
                    </Tile>
                </div>
            </div>
        </div>
    );
};