import { css } from "@fluentui/utilities/lib/css";
import * as React from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; // import first
import { CompaniesPage } from "../pages/CompaniesPage/CompaniesPage";
import { UsersPage } from "../pages/UsersPage/UsersPage";
import useStyles from "./AdminPanel.styles";
import { Layout } from "./Layout/Layout";
import { ProtectedRoute } from "./ProtectedRoute/ProtectedRoute";
import { TemplatePage } from "../pages/TemplatePage/TemplatePage";

export interface IAppProps {
}

// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
export default function App(props: IAppProps) {
    const styles = useStyles();
    return (
        <div className={css(styles.container)}>
            <Layout>
                <Routes>
                    <Route path="/unauthorized" element={<>Unauthorized</>} />
                    <Route path="/" element={<ProtectedRoute><>Start</></ProtectedRoute>} />
                    <Route path="/user" element={<ProtectedRoute><UsersPage /></ProtectedRoute>} />
                    <Route path="/template" element={<ProtectedRoute><TemplatePage /></ProtectedRoute>} />
                    <Route path="/company" element={<ProtectedRoute><CompaniesPage></CompaniesPage></ProtectedRoute>} />
                </Routes>
            </Layout>
            <ToastContainer />
        </div>
    );
}