import { useMsal } from "@azure/msal-react";
import { IconButton, IContextualMenuProps } from "@fluentui/react";
import { IPersonaSharedProps, Persona, PersonaSize } from "@fluentui/react/lib/Persona";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSessionStore } from "../../state/useSessionStore";
import useStyles from "./UserControlPanel.styles";


export interface UserControlPanelProps {
    disabled?: boolean;
    checked?: boolean;
}


export default function UserControlPanel(props: UserControlPanelProps) {
    const { t } = useTranslation("translation", { keyPrefix: "userControlPanel" });
    const styles = useStyles();
    const { instance } = useMsal();
    const store = useSessionStore();
    const [userContextMenuIsVisible, toggleMenu] = useState(false);
    const { disabled, checked } = props;
    

    // React.useEffect(()=>{
    //     console.log("account:", accounts[0]);
    //     if(accounts && accounts.length > 0 && accounts[0].username && !store.displayName){
    //         store.setDisplayName(accounts[0].username)
    //     }
    // },[accounts, store.displayName]);

    const handleLogout = React.useCallback(() => {
           instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });

    },[instance]);

    const menuProps: IContextualMenuProps = useMemo( ()=> {
        let result: IContextualMenuProps= {
            items: [
                {
                    key: "logout",
                    text: t("menu.logout"),
                    iconProps: { iconName: "Leave" },
                    onClick: handleLogout,
                    itemProps: {
                        id: "logout"
                    }
                },
            ],
            directionalHintFixed: true,
            alignTargetEdge: true,
            
        };


        return result;
    }, [handleLogout, t]) ;

    const personaData: IPersonaSharedProps = useMemo(()=> {
        return {
            imageUrl: "/api/me/photo",
            text: store.currentUser?.displayName
            // secondaryText: login,
        };
    }, [store.currentUser?.displayName]);

    return (<div className={styles.userControlPanel}>
            <IconButton
                menuProps={menuProps}
                title={t("menu.title")}
                ariaLabel={t("menu.title")}
                disabled={disabled}
                checked={checked}
            >
                <Persona
                    {...personaData}
                    size={PersonaSize.size32}
                    // presence={PersonaPresence.away}
                    hidePersonaDetails={false}
                    onClick={() => toggleMenu(!userContextMenuIsVisible)}
                />
            </IconButton>
        </div>);
}