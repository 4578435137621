import React from "react";
import { useFetch } from "../../api/fetch";
import useGenericDeleteApiCall from "../../api/useGenericDeleteApiCall";
import useTranslateLanguage from "../../hooks/useTranslateLanguage";
import { LocationLanguage } from "../../model/LocationLanguage";
import { NullableOptional } from "../../model/nullable";
import { generatePatchDoc } from "../../utils/buildPatchDoc";
import { useTokenApi } from "./tokenApi";


export const useUpdateLanguage = () => {
    const { getToken } = useTokenApi();
    const { translateLanguage } = useTranslateLanguage();
    const { fetchJson } = useFetch();
    
    return React.useCallback(async (id: number, data: Partial<NullableOptional<LocationLanguage>>): Promise<LocationLanguage | undefined> => {
        const token = await getToken();
    
        const body = generatePatchDoc(data);
        let result = await fetchJson(`language/${id}`, token, { method: "PATCH", body: JSON.stringify(body) }) as LocationLanguage | undefined;
        if (result) {
            result = translateLanguage(result);
        }
        return result;
    }, [fetchJson, getToken, translateLanguage]);
}

export const useDeleteLanguage = () => {
    const { getToken } = useTokenApi();
    const genericDeleteApiCall = useGenericDeleteApiCall();

    return React.useCallback(async (id: number): Promise<boolean> => {
        const token = await getToken();
        return await genericDeleteApiCall(`language/${id}`, token);
    }, [genericDeleteApiCall, getToken]);
}
export const useCreateLanguage = () => {
    const { getToken } = useTokenApi();
    const { fetchJson } = useFetch();

    return React.useCallback(async (locationId: number, isoCode: string): Promise<LocationLanguage | undefined> => {
        const token = await getToken();
        const body = {
            locationId: locationId,
            isoCode: isoCode.trim(),
        };
        return await fetchJson(`language`, token, { method: "POST", body: JSON.stringify(body) }) as LocationLanguage;
    }, [fetchJson, getToken]);
}

export const useGetLanguageDependentProfileCount = () => {
    const { getToken } = useTokenApi();
    const { fetchJson } = useFetch();

    return React.useCallback(async (id: number): Promise<number | undefined> => {
        const token = await getToken();
        const result = await fetchJson(`language/countProfiles/${id}`, token) as { count: number } | undefined;
        return result?.count;
    }, [fetchJson, getToken]);
}