import create from "zustand";
import { LoadingState } from "../../model/LoadingState";
import { Template } from "../../model/Template";

interface TemplateState {
    templates: Template[] | undefined;
    setTemplates: (templates: Template[] | undefined) => void;
    loadingState: LoadingState;
    setLoadingState: (loadingState: LoadingState) => void;
}

export const useTemplateStore = create<TemplateState>((set) => ({
    templates: undefined,
    loadingState: "NotLoaded",
    setTemplates: (templates: Template[] | undefined) => set(() => ({templates: templates})),
    setLoadingState: (loadingState: LoadingState) => set(() => ({loadingState: loadingState})),     
}))