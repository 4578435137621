import { SilentRequest } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import React from "react";
import { backendRequest } from "../authConfig";

const useToken = () => {
    const { instance } = useMsal();
    
    const account = instance.getAllAccounts()[0];
    const accountmemo = React.useMemo(() => account, [account?.username]);

    return React.useCallback(async (): Promise<string> => {
        console.log("useTokenCallback")
        const accessTokenRequest = {

            scopes: backendRequest.scopes,
            account: accountmemo,

        } as SilentRequest;
        const response = await instance.acquireTokenSilent(accessTokenRequest);
        return response.accessToken;
    }, [accountmemo, instance])
} 


export interface TokenApi {
    getToken: () => Promise<string>;
}

export const useTokenApi = (): TokenApi => {
    const getToken = useToken();

    const api = React.useMemo(() => {
        return {
            getToken,
        }
    }, [getToken])
    return api;
}